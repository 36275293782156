import styled from "styled-components";

export const Container = styled.div`
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  gap: 10px;
  justify-content: end;

  p {
    font-size: 12px;
    line-height: 14px;
  }
`;

export const CanvasBox = styled.div`
  height: 80%;
`;

export const Label = styled.div`
  position: absolute;
  left: ${({ left }) => (left ? left : "")};
  top: ${({ top }) => (top ? top : "")};
  bottom: ${({ bottom }) => (bottom ? bottom : "")};
  right: ${({ right }) => (right ? right : "")};
  width: 30px;
`;
