import axios from "./BaseService";

export const createUser = async(user) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/users`, user);
    return response.data;
  } catch(err) {
    if (err.response) {
      throw new Error(
        JSON.stringify({
          error: err.response.statusText,
          status: err.response.status,
        })
      );
    }

    console.error('Erro ao buscar create user:', err.message);
    throw err;
  }
}
