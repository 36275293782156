import React from "react";
import { Container, Header, Main, Footer } from "./layoutWithoutMenu.style";

import logo from "../assets/logo.png";
import fundo from "../assets/fundo.png";

import LanguageSelect from "../../components/LanguageSelect/LanguageSelect";

export default function LayoutWithoutMenu({ children }) {
  return (
    <Container>
      <Header>
        <img src={logo} alt="Sumply logo" />
        <LanguageSelect />
      </Header>
      <Main>{children}</Main>
      <Footer>
        <img src={fundo} alt="fundo logo" />
      </Footer>
    </Container>
  );
}
