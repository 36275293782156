import React, { useState } from "react";
import { SelectItem } from "@carbon/react";

import * as S from "./LanguageSelect.style";

export default function LanguageSelect({ blackBackground }) {
  const [language, setLanguage] = useState(localStorage.getItem("i18nextLng"));

  const I18N_STORAGE_KEY = "i18nextLng";

  function changeLanguage(languageCode) {
    localStorage.setItem(I18N_STORAGE_KEY, languageCode);
    window.location.reload(false);
  }

  return (
    <S.Select
      labelText=""
      value={language}
      onChange={(e) => {
        setLanguage(e.target.value);
        changeLanguage(e.target.value);
      }}
      blackBackground={blackBackground}
    >
      <SelectItem text="Português" value="pt" />
      <SelectItem text="English" value="en" />
    </S.Select>
  );
}
