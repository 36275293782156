import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
    .cds--modal-header {
        margin-block-end: 0.5rem;
        padding-block-start: 1rem;
        padding-inline: 1rem 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
`;

export default GlobalStyles;
