import React from "react";

import * as S from "./CoverageChart.style";

import { i18n } from "../../../translate/i18n";

export default function CoverageChart({ number }) {
  const { t } = i18n;

  const originalPointerPosition = number / 1.33;
  const pointerPosition =
    originalPointerPosition > 100 ? 100 : originalPointerPosition;

  const numberPosition = pointerPosition > 90 ? 90 : pointerPosition;

  return (
    <S.Container>
      <S.Number width={numberPosition}>
        <p>
          <strong>
            {number} {t("days").toLowerCase()}
          </strong>
        </p>
      </S.Number>
      <S.Pointer width={pointerPosition} />
      <S.ColorScale />
      <S.Legend>
        <p>{t("critical").toLowerCase()}</p>
        <p>{t("excellent").toLowerCase()}</p>
        <p>{t("critical").toLowerCase()}</p>
      </S.Legend>
    </S.Container>
  );
}
