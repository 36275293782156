import { useRouteError } from "react-router-dom";
import { i18n } from "../translate/i18n";

export default function ErrorPage() {
  const { t } = i18n;

  const error = useRouteError();
  console.error(error);

  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p>{t("unexpectedError")}</p>
      <p>
        <i>erro</i>
      </p>
    </div>
  );
}
