import React, { useEffect, useState } from "react";
import {
  Header,
  SkipToContent,
  HeaderMenuButton,
  HeaderName,
  HeaderNavigation,
  HeaderMenuItem,
  HeaderGlobalAction,
  HeaderGlobalBar,
  SideNav,
  SideNavItems,
  HeaderSideNavItems,
  Theme, Button,
} from "@carbon/react";
import { UserAvatar } from "@carbon/icons-react";
import { useNavigate } from "react-router-dom";
import { getLatestPlan } from "../../services/PlanningService";
import { i18n } from "../../translate/i18n";

import Logo from "../Logo";
import LanguageSelect from "../LanguageSelect/LanguageSelect";

export default function HeaderUI() {
  const { t } = i18n;

  const [isSideNavExpanded, setIsSideNavExpanded] = useState(false);
  const [planRoute, setPlanRoute] = useState("");

  const navigate = useNavigate();

  const onClickSideNavExpand = () => {
    setIsSideNavExpanded(!isSideNavExpanded);
  };

  const logout = () => {
    localStorage.removeItem("token");
    navigate("/");
  };

  useEffect(() => {
    const resolveRoute = async () => {
      const token = localStorage.getItem("token");
      const plan = await getLatestPlan(token);
      setPlanRoute(plan?.startDate ? "/otb" : "/createPlan");
    };

    resolveRoute();
  }, []);

  return (
    <Theme theme="g100">
      <Header aria-label="Sumply">
        <SkipToContent />
        <HeaderMenuButton
          aria-label={isSideNavExpanded ? t("closeMenu") : t("openMenu")}
          onClick={onClickSideNavExpand}
          isActive={isSideNavExpanded}
          aria-expanded={isSideNavExpanded}
        />
        <HeaderName href="/" prefix="">
          <Logo />
        </HeaderName>
        <HeaderNavigation aria-label="Sumply">
          <HeaderMenuItem
            isActive={window.location.pathname === "/home"}
            href="/home"
          >
            {t("opportunities")}
          </HeaderMenuItem>
          <HeaderMenuItem
            isActive={
              window.location.pathname === "/createPlan" ||
              window.location.pathname === "/otb"
            }
            // href={planRoute}
            href="/createPlan"
          >
            {t("salesPlan")}
          </HeaderMenuItem>
          {/* <HeaderMenuItem href="/otb">{t("productMix")}</HeaderMenuItem> */}
        </HeaderNavigation>
        <HeaderGlobalBar>
          <LanguageSelect blackBackground />
          <Button
              kind="ghost"
              onClick={() => {
                logout();
              }}
          >
            {t("logout")}
          </Button>
        </HeaderGlobalBar>

        {/*<HeaderGlobalBar>*/}
        {/*  <LanguageSelect blackBackground />*/}
        {/*  <HeaderGlobalAction*/}
        {/*    aria-label={t("profile")}*/}
        {/*    onClick={() => {*/}
        {/*      logout();*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    <UserAvatar size={20} />*/}
        {/*  </HeaderGlobalAction>*/}
        {/*</HeaderGlobalBar>*/}
        <SideNav
          aria-label="Side navigation"
          expanded={isSideNavExpanded}
          isPersistent={false}
          onSideNavBlur={onClickSideNavExpand}
        >
          <SideNavItems>
            <HeaderSideNavItems>
              <HeaderMenuItem href="#">{t("opportunities")}</HeaderMenuItem>
              <HeaderMenuItem href="#">{t("salesPlan")}</HeaderMenuItem>
              <HeaderMenuItem href="#">{t("productMix")}</HeaderMenuItem>
            </HeaderSideNavItems>
          </SideNavItems>
        </SideNav>
      </Header>
    </Theme>
  );
}
