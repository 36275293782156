import axios from "axios";

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('sumply:access_token');
    const tenant = JSON.parse(localStorage.getItem('tenant'));

    config.headers["Content-Type"] = 'application/json';

    if (!config.url.includes('/api/auth/login') && !config.url.includes('/api/auth/logout') && token) config.headers.Authorization = `Bearer ${token}`;
    if (tenant) config.headers['x-tenant'] = tenant.tenant;

    return config;
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401 && window.location.pathname !== "/") {
      console.error(`Redirected to login by 401!`);
      localStorage.removeItem('sumply:access_token');
      window.location = "/";
    } 
    return Promise.reject(error);
  }
);

export default axios;
