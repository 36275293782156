import React, { useState } from "react";
import { TextInput } from "@carbon/react";
import { ChevronRight } from "@carbon/icons-react";
import { useNavigate } from "react-router-dom";
import { i18n } from "../../translate/i18n";

import LayoutWithoutMenu from "../../layouts/LayoutWithoutMenu/LayoutWithoutMenu";
import ButtonDefault from "../../components/ButtonDefault/ButtonDefault";

import * as S from "./resetPassword.style";

const { t } = i18n;

const PasswordProps = {
  id: "TextPassword",
  labelText: "",
  placeholder: t("password"),
  autoComplete: true,
  invalid: false,
  invalidText: t("invalidPassword"),
  required: true,
  helperText: t("passwordHelperText"),
};

const ConfirmPasswordProps = {
  id: "TextPasswordConfirm",
  labelText: "",
  placeholder: t("confirmPassword"),
  autoComplete: true,
  invalid: false,
  invalidText: t("invalidConfirmPassword"),
  required: true,
};

export default function ResetPassword() {
  const [password, setPassword] = useState("");
  const [confirPassword, setConfirPassword] = useState("");
  const [showSucess, setShowSucess] = useState(false);
  const [erroStatusPassword, setErroStatusPassword] = useState(false);
  const [erroStatusPasswordEg, setErroStatusPasswordEg] = useState(false);

  const navigate = useNavigate();

  const handleCheckPassword = (e) => {
    e.preventDefault();
    if (password && confirPassword) {
      if (password === confirPassword) {
        setShowSucess(!showSucess);
      } else {
        setErroStatusPasswordEg(!erroStatusPassword);
      }
    } else {
      setErroStatusPassword(!erroStatusPassword);
    }
  };

  return (
    <LayoutWithoutMenu>
      <S.session>
        {!showSucess && (
          <>
            <S.title>
              <h3>{t("newPassword")}</h3>
              <h4>{t("enterNewPassword")}</h4>
            </S.title>
            <S.form onSubmit={handleCheckPassword}>
              <TextInput.PasswordInput
                {...PasswordProps}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                invalid={erroStatusPassword}
              />
              <TextInput.PasswordInput
                {...ConfirmPasswordProps}
                onChange={(e) => {
                  setConfirPassword(e.target.value);
                }}
                invalid={erroStatusPasswordEg}
              />

              <ButtonDefault
                type="submit"
                className="submitBtn"
                renderIcon={() => <ChevronRight />}
              >
                {t("continue")}
              </ButtonDefault>
            </S.form>
          </>
        )}
        {showSucess && (
          <>
            <S.title>
              <h3>{t("passwordChanged")}</h3>
              <h4>{t("canLoginNow")}</h4>
            </S.title>
            <ButtonDefault
              type="button"
              className="toBack"
              renderIcon={() => <ChevronRight />}
              onClick={() => {
                navigate("/");
              }}
            >
              {t("goToLogin")}
            </ButtonDefault>
          </>
        )}
      </S.session>
    </LayoutWithoutMenu>
  );
}
