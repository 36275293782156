import React, { useState } from "react";
import {
  Button,
  Link,
  ComposedModal,
  ModalHeader,
  ModalBody,
} from "@carbon/react";
import {
  Connect,
  DataConnected,
  ArrowLeft,
  ArrowRight,
  ProgressBarRound
} from "@carbon/icons-react";
import { useNavigate } from "react-router-dom";
import { i18n } from "../../../translate/i18n";

import constsLogos from "../../../consts/logoConsts";
import { createUser } from "../../../services/UsersService";
import { createCompany } from "../../../services/CompaniesService";
import { startConfig} from "../../../services/StartConfig.service";
import { getProducts } from '../../../services/Products.service';

import * as S from "./IntegrationStep.style";

import RenderLogo from "../../../components/RenderLogo/RenderLogo";
import Label from "../../../components/Label/Label";
import ButtonDefault from "../../../components/ButtonDefault/ButtonDefault";

export default function IntegrationStep({ handlePreviousStep, formData }) {
  const { t } = i18n;

  const navigate = useNavigate();

  const [sucess, setSucess] = useState(false);
  const [registerError, setRegisterError] = useState(false);
  const [disabledBtnFinish, setDisabledBtnFinish] = useState(true);
  const [typeConnectionERP, setTypeConnectionERP] = useState();
  const [statusBtnConnectionERP, setStatusBtnConnectionERP] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleConectar = (item) => {
    // navigate("/createPlan");
    setLoading(true)
    
    setTimeout(function() {
      setTypeConnectionERP(item)
      setStatusBtnConnectionERP(true)
      setDisabledBtnFinish(false)
      setLoading(false)    
    }, 2000);
  };

  //console.log(typeConnectionERP)
  //console.log(statusBtnConnectionERP)

  const handleConfirmation = async () => {
    const newUser = {
      ...formData,
      first: true,
    };

    const newCompany = {
      companyName: formData.companyName,
      sector: formData.sector,
      employeeNumber: formData.employeeNumber,
    };

    try {
      await createCompany(newCompany);
      await createUser(newUser);
      // const response = await doLogin(newUser.email, newUser.password);
      const response = {token: '' }
      if (response.token) {
        localStorage.setItem("token", response.token);

        await startConfig();
        await getProducts('15');
        
        navigate("/home");
      }
    } catch (error) {
      console.log(error);
      setRegisterError(true);
    }
  };

  const handleSkipStep = () => {
    handleConfirmation();
  };

  const handleCheckStatusBtnConnectionERP = (item) => {
    if (statusBtnConnectionERP === true && typeConnectionERP === 'linx' && item === 'linx') {
      return "listItemDirectionEnd";
    } else if (!statusBtnConnectionERP) {
      return "listItemDirectionEnd";
    } else {
      return "listItemDisabled";
    }
  }

  const handleShowNameBtnERP = (item) => {
    if (statusBtnConnectionERP === true && typeConnectionERP === 'linx' && item === 'linx') {
      return "connected";
    } else if (!statusBtnConnectionERP) {
      return "connect";
    } else {
      return "connect";
    }
  }

  if (loading) {
    return (
      <S.loading>
        <ProgressBarRound className="icon-spin" size={60} color="#41a05f" />
        <span>Loading...</span>
      </S.loading>
    )
  } 

  return (
    <>
      <S.Article>
        <h3>{t("ensureContinuousFlow")}</h3>
        <h4>{t("optimizeInternalProcesses")}</h4>
        <p className="firstParagraph">{t("integrationDescription")}</p>
        <p>{t("decisionMakingWithRealTimeData")}</p>
      </S.Article>
      <S.Session>
        {!sucess && (
          <div className="content">
            <div className="p">
              <Label text={t("connectToERP")} />
            </div>
            <div>
              {constsLogos.map((item) => (
                <S.ListContainer key={item}>
                  {RenderLogo(item)}
                  <div className={handleCheckStatusBtnConnectionERP(item)}>
                    <Button
                      size="md"
                      kind="ghost"
                      renderIcon={Connect}
                      iconDescription="Connect"
                      className="buttom"
                      disabled={statusBtnConnectionERP}
                      onClick={() => handleConectar(item)}
                    >
                      {t(handleShowNameBtnERP(item))}
                    </Button>
                  </div>
                </S.ListContainer>
              ))}
            </div>

            <S.ButtonSet>
              <S.Button kind="secondary" onClick={handlePreviousStep}>
                <ArrowLeft /> {t("back")}
              </S.Button>
              <S.Button
                primary
                disabled={disabledBtnFinish}
                renderIcon={() => <ArrowRight />}
                onClick={handleSkipStep}
              >
                {t("finish")}
              </S.Button>
            </S.ButtonSet>

            <S.TextWithLink>
              <Label>
                {t("erpNotListed")}
                <Link className="linkColor">{t("contactUs")}</Link>
              </Label>
            </S.TextWithLink>
            {/* <S.TextWithLink>
              <Label>
                {t("skipERPConnection")}
                <Link className="linkColor" onClick={handleSkipStep}>
                  {t("skipThisStep")}
                </Link>
              </Label>
            </S.TextWithLink> */}
          </div>
        )}
        {sucess && (
          <div className="content">
            <div className="p">
              <Label text={t("connectToERP")} />
            </div>
            <div>
              <S.ListContainer>
                {RenderLogo("contaAzul")}
                <div className="listItemDirectionEnd">
                  <Button
                    size="md"
                    kind="ghost"
                    renderIcon={DataConnected}
                    iconDescription="DataConnected"
                    className="buttom"
                  >
                    {t("disconnect")}
                  </Button>
                </div>
              </S.ListContainer>
            </div>
            <S.TextWithLink>
              <Label>
                {t("erpNotListed")}
                <Link className="linkColor">{t("contactUs")}</Link>
              </Label>
            </S.TextWithLink>

            <S.ButtonSet>
              <S.Button kind="secondary" onClick={handlePreviousStep}>
                <ArrowLeft /> {t("back")}
              </S.Button>
              <S.Button
                primary
                renderIcon={() => <ArrowRight />}
                onClick={handleSkipStep}
              >
                {t("finish")}
              </S.Button>
            </S.ButtonSet>
          </div>
        )}
      </S.Session>

      <ComposedModal
        onClose={() => setRegisterError(false)}
        open={registerError}
        size="sm"
      >
        <ModalHeader title={t("userRegistrationError")} />
        <ModalBody>{t("userRegistrationErrorMessage")}</ModalBody>
        <ButtonDefault kind="secondary" onClick={() => setRegisterError(false)}>
          {t("return")}
        </ButtonDefault>
      </ComposedModal>
    </>
  );
}
