import styled from "styled-components";
import { Select as CSelect } from "@carbon/react";

export const Select = styled(CSelect)`
  max-width: 150px;

  .cds--select-input {
    background-color: ${({ blackBackground }) =>
      blackBackground ? "#161616" : "#f4f4f4"};
  }

  .cds--select {
    inline-size: unset;
  }
`;
