import React, {useEffect, useState} from "react";
import Card from "./Card/Card";
import * as S from "./dashboard.style";
import Table from "./Table/Table";
import {
    BuildingInsights_3,
    Currency,
    DeliveryParcel,
    ProgressBarRound,
} from "@carbon/icons-react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";

import ChartDataLabels from "chartjs-plugin-datalabels";
import {Dropdown} from "@carbon/react";
import {getColorForPercentage} from "../../styles/helpers/colors";
import {i18n} from "../../translate/i18n";
import PerformanceChart from "./PerformanceChart/PerformanceChart";
import VelocityChart from "./VelocityChart/VelocityChart";
import CoverageChart from "./CoverageChart/CoverageChart";
import HealthChart from "./HealthChart/HealthChart";
import {LoadingLayout} from "../../layouts/loadingLayout";
import {temporadas} from "./dashboard.helpers";
import {useDispatch, useSelector} from "react-redux";
import {setSelectedTemporada} from "./dashboard.reducer";
import {
    useGetMetricsPerformanceQuery,
    useGetStockDaysWithPercentQuery,
    useGetClusterValuesQuery
} from "./dashboard.service";
import {useGetProductsQuery} from "../Products/products.service";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    ChartDataLabels,
    Title,
    Tooltip,
    Legend
);


export default function Dashboard() {
    const {t} = i18n;
    const selectedTemporada = useSelector((state) => state.dashboard.selectedTemporada);
    const dispatch = useDispatch();

    const {
        data: { cluster, sellThrough, age, giro, sales, stock, business } = {},
        isError: isMetricsPerformanceErros,
        isSuccess: isMetricsPerformanceSuccess,
        isLoading: isMetricsPerformanceLoading
    } = useGetMetricsPerformanceQuery(selectedTemporada.id);

    const {
        data: stockDaysWithPercent ,
        isError: isStocksDaysWithPercentErros,
        isSuccess: isStocksDaysWithPercentSuccess,
        isLoading: isStocksDaysWithPercentLoading
    } = useGetStockDaysWithPercentQuery(selectedTemporada.id);

    const {
        data: clusterValues ,
        isError: isClusterValuesErros,
        isSuccess: isClusterValuesSuccess,
        isLoading: isClusterValuesLoading
    } = useGetClusterValuesQuery(selectedTemporada.id);

    const {
        data: productMetrics,
        isError: isProductMetricsErros,
        isSuccess: isProductMetricsSuccess,
        isLoading: isProductMetricsLoading
    } = useGetProductsQuery(selectedTemporada.id);

    // const categorias = [{id: "all", text: t("allFem")}];
    // const canais = [{id: "all", text: t("allMasc")}];

    const velocityNumber = Number(
        sellThrough?.percentage
    );

    if (isMetricsPerformanceLoading || isStocksDaysWithPercentLoading) {
        return (
            <LoadingLayout/>
        );
    }

    return (
        <>
            <S.title border>
                <h3>{t("opportunities")}</h3>
            </S.title>

            <S.section>
                <S.section className="filterWrapper">
                    <div className="filters">
                        <span className="sub">{t("seasons")}</span>
                        <Dropdown
                            id="temporadas"
                            type="inline"
                            label={t("seasons")}
                            items={temporadas}
                            itemToString={(item) => (item ? item.text : "")}
                            selectedItem={selectedTemporada}
                            onChange={(item) => {
                                dispatch(setSelectedTemporada(item.selectedItem));
                            }}
                        />
                        {/*<span className="sub">{t("categories")}</span>*/}
                        {/*<Dropdown*/}
                        {/*  id="categorias"*/}
                        {/*  type="inline"*/}
                        {/*  label={t("categories")}*/}
                        {/*  items={categorias}*/}
                        {/*  itemToString={(item) => (item ? item.text : "")}*/}
                        {/*  selectedItem={categorias[0]}*/}
                        {/*/>*/}
                        {/*<span className="sub">{t("channels")}</span>*/}
                        {/*<Dropdown*/}
                        {/*  id="canais"*/}
                        {/*  type="inline"*/}
                        {/*  label={t("channels")}*/}
                        {/*  items={canais}*/}
                        {/*  itemToString={(item) => (item ? item.text : "")}*/}
                        {/*  selectedItem={canais[0]}*/}
                        {/*/>*/}
                    </div>
                </S.section>

                <S.section center style={{marginTop: "15px"}}>
                    <Card
                        icon={<BuildingInsights_3 size={24} color="#777A7E"/>}
                        title={t("business")}
                        tag={t(business?.classify.toLowerCase())}
                        score={2}
                        bigInfo={
                            <span
                                style={{
                                    color: getColorForPercentage(
                                        business?.percentage
                                    ),
                                }}
                            >
                {business?.percentage}%
              </span>
                        }
                        parent
                    />
                </S.section>

                <S.section center row style={{alignItems: "flex-start"}}>
                    <S.section center style={{width: "50%", padding: "0 16px"}}>
                        {/* Card Vendas */}
                        <Card
                            icon={<Currency size={24} color="#777A7E"/>}
                            title={t("sales")}
                            tag={t(sales?.classify.toLowerCase())}
                            score={1}
                            bigInfo={
                                <span
                                    style={{
                                        color: getColorForPercentage(
                                            sales?.percentage
                                        ),
                                    }}
                                >
                  {sales?.percentage}%
                </span>
                            }
                            parent
                            child
                        />

                        <S.section center row gap>
                            <Card
                                icon={<Currency size={24} color="#777A7E"/>}
                                subtitle={t("factor")}
                                title={t("performance")}
                                tag={t(cluster?.classify.toLowerCase())}
                                score={1}
                                bigInfo={
                                    <span
                                        style={{
                                            color: getColorForPercentage(
                                                cluster?.percentage
                                            ),
                                        }}
                                    >
                    {cluster?.percentage}%
                  </span>
                                }
                                child
                            />
                            <Card
                                icon={<DeliveryParcel size={24} color="#777A7E"/>}
                                subtitle={t("factor")}
                                title={t("velocity")}
                                tag={t(
                                   sellThrough?.classify.toLowerCase()
                                )}
                                score={1}
                                bigInfo={
                                    <span
                                        style={{
                                            color: getColorForPercentage(
                                                sellThrough?.percentage
                                            ),
                                        }}
                                    >
                    {sellThrough?.percentage}%
                  </span>
                                }
                                child
                            />
                        </S.section>
                        <S.section center row gap>
                            <S.chartCard>
                                <S.chartCardTitle>
                                    {t("percentShareByCluster")}
                                </S.chartCardTitle>
                                <S.chartWrapper>
                                    <PerformanceChart clusters={clusterValues}/>
                                </S.chartWrapper>
                            </S.chartCard>
                            <S.chartCard>
                                <S.chartCardTitle>{t("velocity")}</S.chartCardTitle>
                                <S.chartWrapper>
                                    <VelocityChart value={velocityNumber}/>
                                </S.chartWrapper>
                            </S.chartCard>
                        </S.section>
                    </S.section>

                    <S.section center style={{width: "50%", padding: "0 16px"}}>
                        {/* Card Estoque */}
                        <Card
                            icon={<Currency size={24} color="#777A7E"/>}
                            title={t("stock")}
                            tag={t(stock?.classify.toLowerCase())}
                            score={4}
                            bigInfo={
                                <span
                                    style={{
                                        color: getColorForPercentage(
                                            stock?.percentage
                                        ),
                                    }}
                                >
                  {stock?.percentage}%
                </span>
                            }
                            parent
                            child
                        />

                        <S.section center row gap>
                            {/* Card Fator - saúde */}
                            <Card
                                icon={<Currency size={24} color="#777A7E"/>}
                                subtitle={t("factor")}
                                title={t("health")}
                                tag={t(giro?.classify.toLowerCase())}
                                score={1}
                                bigInfo={
                                    <span
                                        style={{
                                            color: getColorForPercentage(
                                                giro?.percentage
                                            ),
                                        }}
                                    >
                    {giro?.percentage}%
                  </span>
                                }
                                child
                            />

                            {/* Card Fator - cobertura */}
                            <Card
                                icon={<DeliveryParcel size={24} color="#777A7E"/>}
                                subtitle={t("factor")}
                                title={t("coverage")}
                                tag={t(age?.classify.toLowerCase())}
                                score={5}
                                bigInfo={
                                    <span
                                        style={{
                                            color: getColorForPercentage(
                                                age?.percentage
                                            ),
                                        }}
                                    >
                    {age?.percentage}%
                  </span>
                                }
                                child
                            />
                        </S.section>

                        <S.section center row gap>
                            {/* Card saúde - gráficos */}
                            <S.chartCard>
                                <S.chartCardTitle>% {t("health")}</S.chartCardTitle>
                                <S.chartWrapper>
                                    <HealthChart
                                        number={giro?.percentage}
                                    />
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                        }}
                                    >
                                        {/* <img src="healthGraph.jpeg" style={{ width: "85%" }} /> */}
                                    </div>
                                </S.chartWrapper>
                            </S.chartCard>
                            <S.chartCard>
                                <S.chartCardTitle>{t("coverageInDays")}</S.chartCardTitle>
                                <S.chartWrapper>
                                    <CoverageChart
                                        number={stockDaysWithPercent?.stockDays}
                                    />
                                </S.chartWrapper>
                            </S.chartCard>
                        </S.section>
                    </S.section>
                </S.section>
                <S.section>
                    <Table
                        temporada={selectedTemporada.id}
                        products={productMetrics}
                        metrics={{age, cluster, giro, sellThrough}}
                    />
                </S.section>
            </S.section>
        </>
    );
}
