import { createSlice } from '@reduxjs/toolkit'
import {temporadas} from "./dashboard.helpers";

const initialState = {
    selectedTemporada: temporadas[0],
}

const dashboardSlice = createSlice({
    name: 'dashboardSlice',
    initialState,
    reducers: {
        setSelectedTemporada(state, { payload }) {
            state.selectedTemporada = payload
        },
    }
})

export const {
    setSelectedTemporada,
} = dashboardSlice.actions

export const dashboardReducer = dashboardSlice.reducer
