import axios from "./BaseService";

export const createCompany = async(company) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/company`, company);
    return response.data;
  } catch(err) {
    if(err.response) {
      throw new Error(
        JSON.stringify({
          error: err.response.statusText,
          status: err.response.status,
        })
      );
    }
    console.error('Erro ao buscar createCompany:', err.message);
    throw err;
  }
}
