import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  p {
    font-size: 12px;
  }
`;

export const Number = styled.div`
  background-color: transparent;
  z-index: 2;
  width: ${({ width }) => (width ? `calc(${width}% + 30px)` : "0%")};
  min-width: 35px;
  display: flex;
  justify-content: end;
`;

export const Pointer = styled.div`
  border-right: 1.5px dashed black;
  background-color: transparent;
  margin-bottom: -30px;
  z-index: 2;
  width: ${({ width }) => (width ? `${width}%` : "0%")};
  height: 40px;
`;

export const ColorScale = styled.div`
  width: 100%;
  height: 30px;
  background: linear-gradient(
    90deg,
    #f02b2b 0%,
    #facd02 25%,
    rgba(8, 158, 4, 1) 50%,
    #facd02 75%,
    #f02b2b 100%
  );
`;

export const Legend = styled.div`
  display: flex;
  justify-content: space-between;
`;
