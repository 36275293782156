import React, { useState } from "react";
import { TextInput, Link } from "@carbon/react";
import { ChevronRight } from "@carbon/icons-react";
import { useNavigate } from "react-router-dom";
import { i18n } from "../../translate/i18n";

import LayoutWithoutMenu from "../../layouts/LayoutWithoutMenu/LayoutWithoutMenu";
import ButtonDefault from "../../components/ButtonDefault/ButtonDefault";

import * as S from "./forgotPassword.style";

const TextInputProps = {
  type: "email",
  id: "TextEmail",
  placeholder: "E-mail",
  labelText: "",
  invalidText: "E-mail invalido",
  required: true,
};

export default function ForgotPassword() {
  const { t } = i18n;
  const [showStatus, setShowStatus] = useState(false);
  const [textEmail, setTextEmail] = useState("");
  const [statusEmail, setStatusEmail] = useState(false);

  const navigate = useNavigate();

  const handleShowStatus = () => {
    if (textEmail && /^[\w-]+@([\w-]+\.)+[\w-]{2,4}$/.test(textEmail)) {
      setShowStatus(!showStatus);
    }
    setStatusEmail(true);
  };

  return (
    <LayoutWithoutMenu>
      {!showStatus && (
        <S.session>
          <S.sessionTitle>
            <h3>{t("recoverPassword")}</h3>
            <h4>{t("typeEmail")}</h4>
          </S.sessionTitle>
          <TextInput
            onChange={(e) => {
              setTextEmail(e.target.value);
            }}
            invalid={statusEmail}
            {...TextInputProps}
          />
          <ButtonDefault
            type="button"
            className="submitBtn"
            renderIcon={() => <ChevronRight />}
            onClick={handleShowStatus}
          >
            {t("continue")}
          </ButtonDefault>
          <div className="linkCameBack">
            <Link href="/">{t("backToLogin")}</Link>
          </div>
        </S.session>
      )}
      {showStatus && (
        <S.session>
          <S.sessionTitle>
            <h3>{t("recoverPassword")}</h3>
            <h4>{t("recoveryEmailSent")}</h4>
          </S.sessionTitle>
          <ButtonDefault
            type="button"
            className="submitBtn"
            renderIcon={() => <ChevronRight />}
            onClick={() => {
              navigate("/");
            }}
          >
            {t("goToLogin")}
          </ButtonDefault>
        </S.session>
      )}
    </LayoutWithoutMenu>
  );
}
