import React from "react";
import {Navigate, Outlet} from "react-router-dom";

export const PublicLayout = ({ children }) => {
    console.log("public layout")
    const token = localStorage.getItem('sumply:access_token');

    if (token) {
        return <Navigate to="/home" replace />;
    }

    return <Outlet />;
};
