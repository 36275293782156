import styled from "styled-components";
import {ProgressBarRound} from "@carbon/icons-react";
import {i18n} from "../translate/i18n";

const Loading = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  height: 100vh;
  justify-content: center;

  .icon-spin {
    animation: rotate 1s linear infinite;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;


export const LoadingLayout = () => {
    const {t} = i18n;

    return (
        <Loading>
            <ProgressBarRound className="icon-spin" size={60} color="#41a05f"/>
            <span>{t("loading")}...</span>
        </Loading>
    );
}