const messages = {
  pt: {
    translations: {
      loginWithGoogle: "Entrar com Google",
      or: "Ou",
      email: "E-mail",
      password: "Senha",
      login: "Entrar",
      invalidCredentials: "Usuário ou senha inválidos",
      forgotPassword: "Esqueci a senha",
      noAccount: "Não possui uma conta?",
      createAccount: "Criar conta",
      recoverPassword: "Recuperação de Senha",
      typeEmail: "Digite seu e-mail utilizado na Sumply",
      continue: "Continuar",
      backToLogin: "Voltar para o login",
      recoveryEmailSent:
        "Enviamos para o seu e-mail um link para resetar sua senha.",
      goToLogin: "Ir para o login",
      authentication: "Autenticação",
      step1Description: "Passo 1: Quem é você?",
      needToKnow: "Precisamos conhecê-lo",
      registration: "Cadastro",
      step2Description: "Passo 2: Se cadastrando em Sumply",
      needDetails: "Precisamos de detalhes",
      integrations: "Integrações",
      step3Description: "Passo 3: Conectar dados",
      needData: "Precisamos de dados!",
      invalidEmail: "Insira um e-mail válido",
      minPassword: "Mínimo 8 caracteres",
      confirmPassword: "Confirme a Senha",
      passwordsDontMatch: "As senhas devem ser iguais",
      continueWithGoogle: "Continuar com Google",
      next: "Avançar",
      agreeWithTerms:
        "Ao clicar em 'Avançar' ou 'Continuar com o Google', você concorda com",
      termsOfUse: "Termos de Uso",
      andThe: "e a",
      privacyPolicy: "Política de Privacidade",
      alreadyHaveAccount: "Já possui uma conta?",
      yourName: "Seu Nome",
      companyName: "Nome da sua empresa",
      companySector: "Setor da sua empresa",
      employeeNumber: "Número de funcionários",
      acceptMailingList: "Eu aceito participar da lista de e-mails da Sumply",
      back: "Voltar",
      autoPartsAndServices: "Auto Peças e Serviços Automotivos",
      cosmeticsAndHygiene: "Cosméticos e Higiene Pessoal",
      pharmaciesAndDrugstores: "Drogarias e Farmácias",
      beautyAndHairSalons: "Estética e Cabeleireiros",
      franchises: "Franquias",
      bookstoresAndStationeries: "Livrarias Papelarias e Afins",
      buildingMaterials: "Materiais para Construção",
      furnitureAndElectronics: "Móveis Eletro e Depto.",
      opticsAndJewelry: "Óticas e Joalherias",
      otherServices: "Outros Serviços",
      otherRetailers: "Outros Varejos",
      gasStations: "Postos de Gasolina",
      recreationAndLeisure: "Recreação e Lazer",
      supermarketsAndHypermarkets: "Supermercados e Hipermercados",
      tourismAndTransport: "Turismo e Transporte",
      specializedFoodRetail: "Varejo Alimentício Especializado",
      clothingAndSportsGoods: "Vestuário e/ou Artigos Esportivos",
      veterinariesAndPetShops: "Veterinárias e Pet-shops",
      ensureContinuousFlow:
        "Garanta um fluxo contínuo e automatizado de informações!",
      optimizeInternalProcesses:
        "Otimize processos internos, eliminando o retrabalho e reduzindo erros.",
      integrationDescription:
        "Com a integração, dados vitais como vendas, estoque e finanças são sincronizadas em tempo real, proporcionando uma visão holística do seu negócio.",
      decisionMakingWithRealTimeData:
        "Tomada de decisões embasadas em informações atualizadas e precisas impulsiona a agilidade operacional, permitindo que sua equipe foque no crescimento do negócio, ao invés de se ocupar com tarefas manuais repetitivas.",
      connectToERP: "Conecte-se ao seu ERP",
      connect: "Conectar",
      connected: "Conectado",
      finish: "Finalizar",
      erpNotListed: "O seu ERP não está na lista?",
      contactUs: "Entre em contato",
      skipERPConnection: "Seguir sem conectar ERP?",
      skipThisStep: "Pule esta etapa",
      disconnect: "Desconectar",
      userRegistrationError: "Erro ao cadastrar usuário",
      userRegistrationErrorMessage:
        "Ocorreu um erro no cadastro do usuário. Por favor, tente novamente ou contate a equipe Sumply.",
      return: "Retornar",
      opportunities: "Oportunidades",
      current: "Atual",
      allFem: "Todas",
      allMasc: "Todos",
      salesPlan: "Plano de Vendas",
      productMix: "Mix de Produtos",
      profile: "Perfil",
      openMenu: "Abrir menu",
      closeMenu: "Fechar menu",
      seasons: "Temporadas",
      categories: "Categorias",
      channels: "Canais",
      business: "Negócio",
      sales: "Vendas",
      factor: "Fator",
      performance: "Performance",
      velocity: "Velocidade",
      salesInValue: "Venda em Valor",
      actualVsProjected: "Realizada x Projetada",
      projected: "Projetado",
      saleInUnits: "Venda em Unidades",
      stock: "Estoque",
      health: "Saúde",
      coverage: "Cobertura",
      stockDays: "Estoque (Dias)",
      value: "Valor",
      sku: "SKU",
      salesPerformanceAbbreviation: "Perf. de Venda",
      salesSpeedAbbreviation: "Vel. de Venda",
      stockHealth: "Saúde de Estoque",
      stockCoverageAbbreviation: "Cob. de Estoque",
      revenueValue: "Receita (R$)",
      revenueShare: "Receita (Share)",
      revenueGrowthLastYear: "Cresc. Receita vs Último Ano",
      averageSellingPrice: "Preço Médio de Venda (R$)",
      salesValueShare: "Share Valor de Venda (%)",
      stockUnits: "Estoque (Pçs)",
      stockValue: "Estoque (R$)",
      coverageDays: "Cobertura (Dias)",
      excellent: "Excelente",
      great: "Ótimo",
      regular: "Regular",
      critical: "Crítico",
      speed: "Velocidade",
      planning: "Planejamento",
      planOverview: "Visão Geral do Planejamento",
      businessView: "Visão de Negócio",
      currentStockSituation: "Situação atual do estoque",
      totalOpenActions: "Total de ações em aberto",
      totalROIInActions: "ROI total em ações",
      generalStatus: "Status Geral",
      stockExcess: "Estoque em excesso",
      stockLack: "Estoque em falta",
      category: "Categoria",
      suggestedActions: "Ações Sugeridas",
      actionsROI: "ROI das Ações",
      status: "Status",
      adjustPlan: "Ajustar Plano",
      actionsAnalysis: "Análise das Ações",
      planAdjustment: "Ajuste do Plano",
      adjustmentAlignment: "Alinhamento dos Ajustes",
      companyStockHealth: "Saúde de estoque resumido - Visão empresa",
      compareLY: "Comparar ano passado",
      excess: "Excesso",
      lack: "Falta",
      unitSales: "Venda em peças",
      growthPercent: "% Cresc. x LY",
      LY: "LY",
      categoryStockHealth: "Saúde de estoque - Visão Categoria",
      selectCategory: "Selecione uma categoria",
      loadingSimulation: "Carregando simulação...",
      successAdjustPlan: "O planejamento foi ajustado com sucesso!",
      errorAdjustPlan:
        "Ocorreu um erro ao ajustar o planejamento. Tente novamente ou contate o administrador do sistema.",
      simulate: "Simular",
      refreshToSimulate: "Recarregue a página para simular",
      idealStock: "Estoque ideal para a venda",
      stockExcessOrLack: "Excesso/Falta de estoque",
      salesShare: "Share venda",
      salesTarget: "Meta de venda",
      currencySales: "Venda R$",
      averagePrice: "Preço médio",
      inventoryPortfolio: "Carteira",
      receiptProjection: "Projeção de recebimento",
      finalMonthStock: "Estoque final do mês",
      unitStock: "Estoque em peças",
      stockTurnover: "Giro de estoque",
      saveValues: "Salvar valores",
      toOriginalValues: "Retornar valores originais",
      calculate: "Calcular",
      salesChannels: "Canais de venda",
      leadTime: "Tempo de entrega",
      commercialDates: "Datas comerciais",
      salesCurve: "Curva de venda",
      createPlan: "Criar Planejamento",
      next6Months: "Próximos 6 meses",
      untilTheEndOfYear: "Até o final do ano",
      next12Months: "Próximos 12 meses",
      untilEndNextYear: "Até o final do próximo ano",
      custom: "Personalizado",
      planningPeriod: "Período de planejamento",
      planningExplanationText:
        "Para iniciar seu planejamento estratégico de vendas e estoque você precisa delimitar o período para o qual irá se planejar e sua meta de crescimento durante esse intervalo. Nós te enviaremos dicas e você poderá fazer ajustes de rota de acordo com suas vendas. Quando esse período estiver perto de acabar nós te avisaremos para criar o próximo.",
      selectPeriodPlanning:
        "Selecione o período para o qual você deseja fazer seu planejamento e qual a projeção do valor que você deseja atingir dentro desse período.",
      salesChannelsExplanation:
        "Você pode separar o seu planejamento de acordo com os canais de venda que sua empresa atua. Isso traz a possibilidade de analisar as vendas e datas de recebimentos especificamente para cada um deles.",
      listSalesChannels:
        "Liste os canais de venda da sua empresa e informe a meta de participação de cada um deles.",
      dontSeparateChannels: "Não quero separar em canais de venda",
      goalParticipation: "Participação da Meta",
      digital: "Digital",
      adjustParticipationSum:
        "Para prosseguir, ajuste a participação para que a soma seja igual a 100%.",
      alt: "Tempo de entrega",
      deliveryTime: "Tempo de entrega",
      leadTimeStepDescription:
        "Agora você vai definir uma média de lead time por categoria. Pense no tempo em semanas entre fazer o pedido ao fornecedor e receber esse pedido em loja. Com esses inputs você poderá otimizar seus níveis de estoque, garantindo que seus produtos estejam disponíveis quando e onde são necessários. Utilizamos as categorias cadastradas para seus itens.",
      averageLeadTimeQuestion:
        "Qual a média de semanas que leva desde você encomendar o produto com o fornecedor até ele chegar em loja?",
      sameTimeForAll:
        "Definir o mesmo tempo de entrega para todas as categorias",
      timeInDays: "Tempo em dias",
      days: "dias",
      fillAllCategories:
        "Preencha o lead time de todas as categorias antes de seguir para a próxima etapa.",
      editCategories: "Editar categorias",
      commercialDatesExplanation:
        "As datas comerciais desempenham um papel crucial para o varejo, representando oportunidades estratégicas para impulsionar as vendas através de um aumento no fluxo de consumo. Tenha um olhar mais apurado sobre elas selecionando aquelas que se alinham de maneira significativa ao perfil do seu negócio. Você também pode adicionar datas personalizadas.",
      selectCommercialDates:
        "Selecione as Datas Comerciais nas quais tem interesse",
      insertCustomDate: "Inserir data comercial personalizada",
      commercialDateName: "Nome da data comercial",
      typeName: "Digite o nome",
      reviewCommercialDate:
        "Por favor, corrija as informações da nova data personalizada. Confira se a data é válida e se não há outra data personalizada igual a esta.",
      maxCommercialDates:
        "O número máximo de datas personalizadas foi atingido. Para inserir outra data, clique sobre uma data personalizada existente para desativá-la.",
      noCommercialDatesConfirmation:
        "As Datas Comerciais são aquelas que impactam na venda do seu varejo e são importantes para calcularmos a curva de demanda de produtos. Ao clicar em avançar, você confirma que nenhuma data comercial impacta na venda dos seus canais?",
      cancel: "Cancelar",
      month: "Mês",
      salesUnits: "Venda (unidades)",
      salesValueParenthesis: "Venda (valor)",
      participation: "Participação",
      salesCurveStepExplanation: "A análise da curva de venda é fundamental para compreender o comportamento das vendas ao longo do tempo. Geramos uma curva de venda recomendada para você com base no histórico de vendas. Analise essa e outras curvas e escolha qual vai usar como modelo de curva de venda do ano.",
      accessories: "Acessórios",
      chart: "Gráfico",
      table: "Tabela",
      salesValue: "Valor de Vendas",
      salesTable: "Tabela de venda",
      otherCurves: "Outras curvas",
      footwear: "Calçados",
      women: "Feminino",
      men: "Masculino",
      children: "Infantil",
      suggested: "Sugerida",
      completePlanning: "Concluir planejamento",
      confirmationOfPlanningData: "Confirmação dos Dados de Planejamento",
      beforeProceedingCheckData:
        "Antes de prosseguir, confira os dados que você inseriu.",
      targetFor: "Meta para",
      targetForPeriod: "Meta para o período",
      noSalesChannelDefined: "Nenhum canal de venda foi definido.",
      physicalStore: "Loja Física",
      wholesale: "Atacado",
      noCommercialDateDefined: "Nenhuma data comercial foi definida.",
      reviewData: "Revisar dados",
      complete: "Concluir",
      pleaseWait: "Aguarde...",
      yourPlanningIsBeingCreated:
        "Seu planejamento está sendo criado... Isso pode levar alguns minutos.",
      success: "Sucesso!",
      yourPlanningWasCreated:
        "Seu planejamento foi criado! Continue para visualizá-lo.",
      goToPlanning: "Ir para planejamento",
      errorOccurred: "Ocorreu um erro.",
      errorCreatingPlanning:
        "Ocorreu um erro na criação do planejamento. Por favor, tente novamente.",
      goBackToReviewPlanning: "Voltar para revisão do planejamento",
      youAreOneStepAway: "Você está a um passo de melhorar seus resultados!",
      growthProjectionExplanation:
        "A Projeção de Crescimento é tudo que precisamos para começar a impactar positivamente o seu negócio.",
      selectMethod: "Selecione o método que seja utilizar:",
      salesTargetForThisYear: "Meta de vendas para esse ano",
      useInflationIndex: "Usar Índice de Inflação",
      enterProjections:
        "Insira a projeção do valor ou do percentual que você deseja crescer este ano.",
      type: "Tipo",
      inflationIndexUpdated: "O índice de inflação é atualizado conforme site",
      insertLYRevenue: "Insira o faturamento do ano anterior",
      invalidPassword: "Senha inválida",
      passwordHelperText: "Mínimo 8 caracteres.",
      invalidConfirmPassword:
        "Confirmar senha: A senha está diferente da digitada anteriormente",
      newPassword: "Nova Senha",
      enterNewPassword: "Digite uma nova senha para a sua conta.",
      passwordChanged: "Senha alterada com sucesso!",
      canLoginNow: "Você já pode efetuar Login.",
      unexpectedError: "Um erro inesperado aconteceu!",
      target: "Meta",
      period: "Período",
      initialMonthError: "O mês inicial deve ser o mês atual ou futuro.",
      minPeriodError: "O período deve ter no mínimo 1 e no máximo 18 meses.",
      name: "Nome",
      periodStartInclusive: "Início do Período (inclusive)",
      periodEndInclusive: "Final do Período (inclusive)",
      selectedPeriod: "Período selecionado",
      date: "Data",
      noDateSelected: "Nenhuma data foi selecionada",
      generalInformation: "Informaçoes Gerais",
      revenueRs: "Receita (RS)",
      revenueGrowth: "Cresc. Receita vs Último Ano",
      averageSalesPrice: "Preço Médio de Venda (R$)",
      stockPcs: "Estoque (Pçs)",
      stockRs: "Estoque (R$)",
      sumplyFactors: "Fatores Sumply",
      salesPerformance: "Performance de Venda",
      salesSpeed: "Velocidade de Venda",
      stockCoverage: "Cobertura do Estoque",
      noMessage: "Sem Mensagem",
      percentShareByCluster: "% Participação Venda por Cluster",
      coverageInDays: "Cobertura (em dias)",
      criticalSlow: "Crítica (Lenta)",
      criticalFast: "Crítica (Veloz)",
      greatFem: "Ótima",
      loading: "Carregando",
    },
  },
};

export { messages };
