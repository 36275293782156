import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const productsApi = createApi({
    reducerPath: 'productsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_URL}/api/products/`,
        prepareHeaders: (headers, { getState }) => {
            const token = localStorage.getItem('sumply:access_token');
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        }
    }),
    endpoints: (builder) => ({
        getProducts: builder.query({
            query: (temporada) => `${temporada}`
        }),
        updateProducts: builder.mutation({
            query: (newProducts) => ({
                url: '',
                method: 'PATCH',
                body: newProducts
            })
        }),
        createProducts: builder.query({
            query: () => `forcedGeneration/15`
        })
    })
});

export const {
    useGetProductsQuery,
    useUpdateProductsMutation,
    useCreateProductsQuery
} = productsApi;