import React from "react";
import { TextInput, Link, Tag } from "@carbon/react";
import { ChevronRight } from "@carbon/icons-react";
import {Outlet, useNavigate} from "react-router-dom";
import { i18n } from "../../translate/i18n";
import LayoutWithoutMenu from "../../layouts/LayoutWithoutMenu/LayoutWithoutMenu";
import ButtonDefault from "../../components/ButtonDefault/ButtonDefault";
import { useRequestLoginMutation } from "./authService";
import { useSelector, useDispatch } from 'react-redux'
import { updateLoginFormField, setUser } from './authReducer'

import * as S from "./login.style";

export default function Login() {
  const [requestLogin, { isLoading: isLoginLoading }] = useRequestLoginMutation();
  const dispatch = useDispatch()
  const { t } = i18n;
  const navigate = useNavigate();
  const email = useSelector(state => state.auth.email)
  const password = useSelector(state => state.auth.password)

  const handleSubmit =async (e) => {
    e.preventDefault();

    try {
      const apiResponse = await requestLogin({email, password}).unwrap();
      if (apiResponse.token && apiResponse.tenant) {
        localStorage.setItem('sumply:access_token', apiResponse.token);
        navigate("/home");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <LayoutWithoutMenu>
      <S.formWrapper>
        {/*<ButtonGoogle label={t("loginWithGoogle")} />*/}
        {/*<S.contentPhaseOr>*/}
        {/*  <i />*/}
        {/*  <span>{t("or").toLowerCase()}</span>*/}
        {/*  <i />*/}
        {/*</S.contentPhaseOr>*/}
        <S.form onSubmit={handleSubmit} noValidate>
          <TextInput
            type="email"
            id="textEmail"
            name="textEmail"
            placeholder={t("email")}
            labelText=""
            value={email}
            invalidText={t("invalidEmail")}
            pattern={/^[\w-]+@([\w-]+\.)+[\w-]{2,4}$/}
            onChange={(e) => {
              dispatch(updateLoginFormField({field:'email', value:e.target.value}))
            }}
          />
          <TextInput.PasswordInput
            type="password"
            name="textPassword"
            id="textPassword"
            labelText=""
            value={password}
            placeholder={t("password")}
            onChange={(e) => {
              dispatch(updateLoginFormField({field:'password', value:e.target.value}))
            }}
          />

          {/*{error && <Tag type="magenta">{t("invalidCredentials")}</Tag>}*/}

          <ButtonDefault
            type="submit"
            className="submitBtn"
            disabled={isLoginLoading}
            renderIcon={() => <ChevronRight />}
          >
            {t("login")}
          </ButtonDefault>

          <div className="linkResetPassword">
            <Link className="link" href="/forgotPassword">
              {t("forgotPassword")}
            </Link>
          </div>

          {/*<div className="linkResetPassword">*/}
          {/*  {t("noAccount")}*/}
          {/*  <Link className="createAccount" href="/createAccount">*/}
          {/*    {t("createAccount")}*/}
          {/*  </Link>*/}
          {/*</div>*/}
        </S.form>
      </S.formWrapper>
    </LayoutWithoutMenu>
  );
}
