import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const authApi = createApi({
    reducerPath: 'authApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_URL}/api/auth/`,
        prepareHeaders: (headers, { getState }) => {
            const token = localStorage.getItem('sumply:access_token')
            if (token) {
                headers.set('authorization', `Bearer ${token}`)
            }
            return headers
        }

    }),
    endpoints: (builder) => ({
        requestLogin: builder.mutation({
            query: (credentials) => ({
                url: 'login',
                method: 'POST',
                body: credentials
            })
        }),
        requestMe: builder.query({
            query: (token) => ({
                url: 'me',
                method: 'GET'
            })
        })

    })
})

export const { useRequestLoginMutation, useRequestMeQuery } = authApi

