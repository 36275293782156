import React from "react";

import { Grid } from "@carbon/react";

import HeaderUI from "../../components/HeaderUI/HeaderUI";
import { Main, Section } from "./layoutWithMenu.style";
import {Outlet} from "react-router-dom";


export default function LayoutWithMenus({
  isMenu = false,
  isCenter = true,
}) {
  return (
    <>
      <HeaderUI />
      <Main isCenter={isCenter} isMenu={isMenu}>
        {isMenu && (
          <>
            {/* <SessionProgress /> */}
            <Section>
              {/* <Aside /> */}
              <article
                style={{
                  backgroundColor: "#F4F5F5",
                }}
              >
                <Outlet />
              </article>
            </Section>
          </>
        )}
        {!isMenu && <Grid><Outlet /></Grid>}
      </Main>
    </>
  );
}
