import React from "react";
import { Bar } from "react-chartjs-2";

export default function PerformanceChart({ clusters }) {
  const values = clusters
    ? clusters.map((cluster) => Math.ceil(cluster.shareVenda))
    : [];

  const colors = [
    "rgb(90, 228, 134)",
    "rgb(77, 193, 114)",
    "rgb(65, 160, 95)",
    "rgb(54, 133, 79, 1)",
  ];

  function reorderColors(values, colors) {
    const sortedIndices = values
      .map((value, index) => ({ value, index }))
      .sort((a, b) => a.value - b.value)
      .map((item) => item.index);
    return sortedIndices.map((index) => colors[index]);
  }

  const data = {
    labels: ["A", "B", "C", "D e E"],
    datasets: [
      {
        label: "Sales",
        data: values,
        backgroundColor: reorderColors(values, colors),
        barPercentage: 1.0, // Full width bars
        categoryPercentage: 1.0, // Remove spacing between categories
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: { display: false },
      tooltip: { enabled: true },
      title: {
        display: false,
      },
      datalabels: {
        display: true,
        align: (context) => {
          const value = context.dataset.data[context.dataIndex];
          return value === 0 ? "end" : "center";
        },
        anchor: (context) => {
          const value = context.dataset.data[context.dataIndex];
          return value === 0 ? "end" : "center";
        },
        formatter: (value) => `${value}%`,
        color: (context) => {
          const value = context.dataset.data[context.dataIndex];
          return value === 0 ? "#525252" : "#ffffff";
        },
        font: {
          size: 14,
          weight: "bold",
        },
      },
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 14,
            weight: "bold",
            family: "Arial",
          },
        },
      },
      y: {
        display: false,
      },
    },
  };

  return <Bar data={data} options={options} />;
}
