import React, { useEffect, useRef } from "react";
import { Chart, ArcElement, Tooltip, DoughnutController } from "chart.js";

import * as S from "./VelocityChart.style";

import { i18n } from "../../../translate/i18n";

Chart.register(ArcElement, Tooltip, DoughnutController);

const GaugeChartWithCustomSections = ({ value }) => {
  const { t } = i18n;

  const chartRef = useRef(null);

  useEffect(() => {
    const ctx = chartRef.current.getContext("2d");

    // Destroy the chart instance if it already exists
    if (chartRef.current.chartInstance) {
      chartRef.current.chartInstance.destroy();
    }

    const gaugeChart = new Chart(ctx, {
      type: "doughnut",
      data: {
        labels: [],
        datasets: [
          {
            data: [10, 25, 25, 25, 10], // Values corresponding to the ranges
            backgroundColor: [
              "#ffeea6", // Light Yellow: 0-5
              "#facd02", // Dark Yellow: 5-9
              "#b5e5a3", // Light Green: 10-15
              "#3b7f24", // Dark Green: 15-20
              "#bd0002", // Red: 20+
            ],
            borderWidth: 0,
            hoverBackgroundColor: [
              "#FFCCCC",
              "#FFD700",
              "#90EE90",
              "#006400",
              "#FF4500",
            ],
            circumference: 180, // Half circle
            rotation: -90, // Starts at leftmost
            cutout: "70%", // Inner radius
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          tooltip: {
            enabled: false,
          },
          legend: {
            display: false,
          },
          datalabels: {
            display: false,
          },
        },
        animation: {
          animateRotate: true,
        },
      },
      plugins: [
        {
          id: "needle",
          beforeDraw: (chart) => {
            const { width, height, ctx } = chart;
            const centerX = width / 2;
            const centerY = height - 10;
            const needleLength = (width / 2) * 0.6;

            // Map value to angle based on ranges
            const mapValueToAngle = (val) => {
              if (val < 5) return val * 0.05;
              if (val < 10) return val * 0.1;
              if (val < 16) return val * 0.1 + 0.4;
              if (val < 21) return val * 0.11 + 0.8;
              return 3.2;
            };

            const needleAngle = mapValueToAngle(value);

            ctx.save();

            // Draw needle
            ctx.translate(centerX, centerY);
            ctx.rotate(needleAngle + 3.05);
            ctx.beginPath();
            ctx.moveTo(0, 0);
            ctx.lineTo(needleLength, 0);
            ctx.lineWidth = 2;
            ctx.strokeStyle = "#000";
            ctx.stroke();
            ctx.restore();

            // Draw needle center circle
            ctx.beginPath();
            ctx.arc(centerX, centerY, 5, 0, 2 * Math.PI);
            ctx.fillStyle = "#000";
            ctx.fill();
          },
        },
      ],
    });

    chartRef.current.chartInstance = gaugeChart;
  }, [value]);

  const labels = [
    { name: t("criticalSlow"), top: "", bottom: "0", left: "0", right: "" },
    { name: t("regular"), top: "15px", bottom: "", left: "25px", right: "" },
    { name: t("excellent"), top: "15px", bottom: "", left: "", right: "35px" },
    { name: t("criticalFast"), top: "", bottom: "0", left: "", right: "10px" },
  ];

  return (
    <S.Container>
      <p>{t("greatFem")}</p>
      <S.CanvasBox>
        <canvas ref={chartRef} style={{ width: "100%", height: "250px" }} />
        {labels.map((label) => (
          <S.Label
            top={label.top}
            bottom={label.bottom}
            left={label.left}
            right={label.right}
          >
            <p>{label.name}</p>
          </S.Label>
        ))}
      </S.CanvasBox>
    </S.Container>
  );
};

export default GaugeChartWithCustomSections;
