import axios from "./BaseService";

export const startConfig = async () => {

  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/start/config`);

    if (!response.ok) {
      throw new Error(
        JSON.stringify({
          error: response.statusText,
          status: response.status,
        })
      );
    }

    return true;
  } catch(err) {
    if (err.response) {
      throw new Error(
        JSON.stringify({
          error: err.response.statusText,
          status: err.response.status,
        })
      );
    }

    console.error('Erro ao buscar start config:', err.message);
    throw err;
  }
};
