import React, { useEffect, useRef } from "react";
import { Chart, registerables } from 'chart.js';
import * as S from "./HealthChart.style";
import PropTypes from "prop-types";

Chart.register(...registerables);

export default function HealthChart({ number }) {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  useEffect(() => {
    if (chartRef && chartRef.current) {

      if (chartInstance.current) chartInstance.current.destroy();

      const ctx = chartRef.current.getContext('2d');
      chartInstance.current = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: ['Semanas'], 
          datasets: [{
            label: 'Giro',
            data: [number ? Number(number) : 0],
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 1
          }]
        },
        options: {
          scales: {
            y: {
              beginAtZero: true,
              max: 100,
            }
          },
          plugins: {
            legend: {
              display: false
            },
            tooltip: {
              callbacks: {
                label: (context) => `${context.parsed.y}%`,
              }
            }
          }
        }
      });
    }

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [number]);

  return (
    <S.Container>
      <canvas ref={chartRef}></canvas>
    </S.Container>
  );
}

HealthChart.propTypes = {
  number: PropTypes.number.isRequired
}