import React, { useState } from "react";
import { TextInput, Link } from "@carbon/react";
import { ChevronRight } from "@carbon/icons-react";
import { i18n } from "../../../translate/i18n";

import * as S from "./CredentialsStep.style";

import ButtonDefault from "../../../components/ButtonDefault/ButtonDefault";
import ButtonGoogle from "../../../components/ButtonGoogle/ButtonGoogle";

export default function CredentialsStep({ handleInputChange, handleNextStep }) {
  const { t } = i18n;

  const [email, setEmail] = useState("");
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [passwords, setPasswords] = useState({
    password: "",
    confirmPassword: "",
  });
  const [invalidPasswordConfirmation, setInvalidPasswordConfirmation] =
    useState(false);

  const handlePassword = (e) => {
    setPasswords({ ...passwords, [e.target.name]: e.target.value });
  };

  const onConfirmPasswordChange = (e) => {
    if (invalidPasswordConfirmation) {
      setInvalidPasswordConfirmation(false);
    }
    handlePassword(e);
  };

  function validateEmail() {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  }

  const handleEmailChange = (e) => {
    if (invalidEmail) {
      setInvalidEmail(false);
    }
    setEmail(e.target.value);
  };

  const emailProps = {
    type: "email",
    name: "email",
    placeholder: t("email"),
    invalid: invalidEmail,
    invalidText: t("invalidEmail"),
    onChange: handleEmailChange,
  };

  const passwordProps = {
    name: "password",
    placeholder: t("password"),
    autoComplete: true,
    helperText: t("minPassword"),
    onChange: handlePassword,
  };

  const confirmPasswordProps = {
    name: "confirmPassword",
    placeholder: t("confirmPassword"),
    invalid: invalidPasswordConfirmation,
    invalidText: t("passwordsDontMatch"),
    onChange: onConfirmPasswordChange,
  };

  const onNext = () => {
    const passwordsMatch = passwords.password === passwords.confirmPassword;

    if (!validateEmail()) {
      setInvalidEmail(true);
    } else if (passwordsMatch && email) {
      const emailEvent = { target: { name: "email", value: email } };
      const passwordEvent = {
        target: { name: "password", value: passwords.password },
      };
      setInvalidPasswordConfirmation(false);
      handleInputChange(emailEvent);
      handleInputChange(passwordEvent);
      handleNextStep();
    } else {
      setInvalidPasswordConfirmation(true);
    }
  };

  const allFieldsComplete = () => {
    const minPassword = passwords.password.length >= 8;
    const maxPassword = passwords.confirmPassword?.length >= 8;
    return email && minPassword && maxPassword;
  };

  return (
    <>
      <S.ContentBtnGoogle>
        <ButtonGoogle label={t("continueWithGoogle")} />
      </S.ContentBtnGoogle>
      <S.ContentPhaseOr>
        <i />
        <span>{t("or")}</span>
        <i />
      </S.ContentPhaseOr>

      <S.Form>
        <TextInput {...emailProps} />
        <TextInput.PasswordInput {...passwordProps} />
        <TextInput.PasswordInput {...confirmPasswordProps} />
        <ButtonDefault
          className="submitBtn"
          renderIcon={() => <ChevronRight />}
          disabled={!allFieldsComplete()}
          onClick={onNext}
        >
          {t("next")}
        </ButtonDefault>
        <div className="termsAndPolicy">
          {t("agreeWithTerms")}{" "}
          <Link className="link" href="/#">
            {t("termsOfUse")}
          </Link>
          {t("andThe")}
          <Link className="link" href="/#">
            {t("privacyPolicy")}
          </Link>
        </div>
        <S.LoginSection>
          <p>{t("alreadyHaveAccount")}</p>
          <Link className="link" href="/">
            {t("login")}
          </Link>
        </S.LoginSection>
      </S.Form>
    </>
  );
}
