import React from "react";
import PropTypes from "prop-types";
import * as S from "./table.style";
import {
    Flash,
    HealthCross,
    Rocket,
    FlashFilled,
    Box,
} from "@carbon/icons-react";

const showRenderIcon = (icon) => {
    switch (icon) {
        case "Rocket": {
            return <Rocket />;
        }
        case "Flash": {
            return <Flash />;
        }
        case "HealthCross": {
            return <HealthCross />;
        }
        case "Box": {
            return <Box />;
        }
        default: {
            return null;
        }
    }
};

const listHeader = (metrics, t) => [
    { title: "#", MultLines: false },
    { title: t("sku"), MultLines: false },
    {
        title: t("salesPerformanceAbbreviation"),
        MultLines: true,
        percent: metrics?.cluster?.percentage,
        icon: "Rocket",
    },
    {
        title: t("salesSpeedAbbreviation"),
        MultLines: true,
        percent: metrics?.sellThrough?.percentage,
        icon: "Flash",
    },
    {
        title: t("stockHealth"),
        MultLines: true,
        percent: metrics?.giro?.percentage,
        icon: "HealthCross",
    },
    {
        title: t("stockCoverageAbbreviation"),
        MultLines: true,
        percent: metrics?.age?.percentage,
        icon: "Box",
    },
    { title: t("revenueValue"), MultLines: false },
    { title: t("revenueShare"), MultLines: false },
    { title: t("revenueGrowthLastYear"), MultLines: false },
    { title: t("averageSellingPrice"), MultLines: false },
    { title: t("salesValueShare"), MultLines: false },
    { title: t("stockUnits"), MultLines: false },
    { title: t("stockValue"), MultLines: false },
    { title: t("coverageDays"), MultLines: false },
];

function TableHeader({ metrics, t }) {
    return (
        <S.theader>
            <S.tr>
                {listHeader(metrics, t).map((item) => {
                    if (item.title === "#") {
                        return (
                            <S.th key={item.title} size={48}>
                                {item.title}
                            </S.th>
                        );
                    }

                    if (item.MultLines) {
                        return (
                            <S.thTwoLine key={item.title}>
                                <span>{item.title}</span>
                                <div className="headerTwoLinePercent">
                                    <span>{item.percent}%</span> {showRenderIcon(item.icon)}
                                </div>
                            </S.thTwoLine>
                        );
                    }
                    return <S.th key={item.title}>{item.title}</S.th>;
                })}
            </S.tr>
        </S.theader>
    );
}

TableHeader.propTypes = {
    metrics: PropTypes.shape({
        cluster: PropTypes.shape({
            percentage: PropTypes.string,
        }),
        sellThrough: PropTypes.shape({
            percentage: PropTypes.string,
        }),
        giro: PropTypes.shape({
            percentage: PropTypes.string,
        }),
        age: PropTypes.shape({
            percentage: PropTypes.string,
        }),
    }),
};

export default TableHeader;
