import styled from "styled-components";
import { Button as CButton } from "@carbon/react";

export const Container = styled.div`
  overflow: scroll;
  .scrollable-div::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  .scrollable-div::-webkit-scrollbar-thumb {
    background-color: #b3b5b5;
    border-radius: 10px;
  }
`;

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;

  p {
    font-size: 20px;
    font-weight: bold;
  }

  .subtitle {
    font-weight: bold;
    font-size: 16px;
    color: #999;
  }
`;

export const UpperBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;

export const ToggleBox = styled.div`
  display: flex;
  padding: 20px;
  margin-top: ${({ negativeMargin }) => (negativeMargin ? "-35px" : "")};
`;

export const TablesBox = styled.div`
  display: flex;

  .light-green th,
  .light-green td {
    background-color: #cbefcb;
  }

  .lighter-green th,
  .lighter-green td {
    background-color: #cbefcb54;
  }
`;

export const Table = styled.table`
  border-collapse: separate;
  border-spacing: 4px;
  table-layout: fixed;

  padding-bottom: ${({ paddingBottom }) =>
    paddingBottom ? paddingBottom : ""};

  thead {
    background-color: white;

    tr {
      th {
        padding: 8px;
        font-size: 12px;
        font-weight: 600;
        height: 28px;
        line-height: 10px;
      }
    }
  }

  tbody {
    tr {
      td,
      th {
        background-color: #dfe0e1;
        font-size: 12px;
        padding: 8px;
        white-space: nowrap;
        height: 28px;
        line-height: 10px;
      }

      th {
        font-weight: 600;
        text-align: left;
        height: 28px;
        line-height: 10px;
      }

      .otb-cell {
        height: 34px;
      }

      &.start-group {
        td,
        th {
          border-top: 8px solid #f4f5f5;
        }
      }

      &.white {
        td,
        th {
          background-color: white;

          input {
            width: 100%;
            border: none;
            font-size: 12px;
          }
        }
      }
    }
  }
`;

export const BodyTable = styled.div`
  overflow: scroll;
`;

export const Tag = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${({ isOver }) => (isOver ? "#fc7d84" : "#41A05F")};

  > div {
    padding: 4px;
    background-color: ${({ isOver }) => (isOver ? "#fc7d84" : "#41A05F")};
    color: white;
    border-radius: 16px;
    line-height: 10px;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  padding: 20px;
`;

export const Button = styled(CButton)`
  background-color: ${({ green }) => (green ? "#68E58F" : "gray")};
  color: ${({ green }) => (green ? "black" : "white")};
`;

export const ProjecaoTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
`;

export const Input = styled.input`
  border: 1px solid #acabab !important;
  border-radius: 2px;
  width: 60px !important;
`;

export const EditButton = styled.button`
  cursor: pointer;
  border: none;
  background-color: unset;
`;

export const SaveModalContainer = styled.div`
  margin: 60px 0;
`;

export const SimulateButtonContent = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  .cds--loading__stroke {
    stroke: #c6c6c6;
  }
`;
