import {configureStore} from '@reduxjs/toolkit'
import {authReducer} from './pages/Login/authReducer'
import {authApi} from "./pages/Login/authService";
import {productsApi} from "./pages/Products/products.service";
import {dashboardApi} from "./pages/Dashboard/dashboard.service";
import {dashboardReducer} from "./pages/Dashboard/dashboard.reducer";

export const store = configureStore({
    reducer: {
        [authApi.reducerPath]: authApi.reducer,
        [dashboardApi.reducerPath]: dashboardApi.reducer,
        [productsApi.reducerPath]: productsApi.reducer,
        auth: authReducer,
        dashboard: dashboardReducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .concat(authApi.middleware)
            .concat(dashboardApi.middleware)
            .concat(productsApi.middleware)

})
