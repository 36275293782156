// router.js - Updated version
import {createBrowserRouter} from "react-router-dom";
import {CreatePlanProvider} from "./contexts/CreatePlanContext";
import GlobalStyles from "./styles/globalStyles/globalStyles";
import Login from "./pages/Login/Login";
import ErrorPage from "./pages/errorPages";
import Dashboard from "./pages/Dashboard/Dashboard";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import CreatePlan from "./pages/CreatePlan/CreatePlan";
import Otb from "./pages/Otb/Otb";
import AccountCreation from "./pages/AccountCreation/AccountCreation";
import StartConfigsAdmin from "./pages/StartConfigsAdmin/StartConfigsAdmin";
import {PublicLayout} from "./layouts/publicLayout";
import {AuthLayout} from "./layouts/AuthLayout/AuthLayout";
import LayoutWithMenus from "./layouts/LayoutWithMenus/LayoutWithMenus";

const AppRoutes = createBrowserRouter([
    {
        path: "/",
        element: <PublicLayout/>,
        children: [
            {
                index: true,
                element: <Login/>,
            },
            {
                path: "forgotPassword",
                element: <ForgotPassword/>,
            },
            {
                path: "resetPassword",
                element: <ResetPassword/>,
            },
            {
                path: "createAccount",
                element: <AccountCreation/>,
            },
        ],
    },
    {
        element: <AuthLayout/>,
        children: [
            {
                element: <LayoutWithMenus isMenu isCenter={false}/>,
                children: [
                    {
                        path: "/home",
                        element: <Dashboard/>,
                    },
                    {
                        path: "/secretAdmin",
                        element: <StartConfigsAdmin/>,
                    },
                ]
            },
        ],
    },
    // {
    //     element: <CreatePlanProvider/>,
    //     children: [
    //         {
    //             path: "createPlan",
    //             element: <CreatePlan/>,
    //         },
    //         {
    //             path: "otb",
    //             element: <Otb/>,
    //         },
    //     ],
    // },
    {
        path: "*",
        element: <ErrorPage/>,
    },
]);

export default AppRoutes;
