import axios from "./BaseService";

export const getLatestPlan = async() =>{
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/planning/latest`);
    return response.data;
  } catch(err) {
    if (err.response) {
      throw new Error(
        JSON.stringify({
          error: err.response.statusText,
          status: err.response.status,
        })
      );
    }

    console.error('Erro ao buscar getLatestPlan:', err.message);
    throw err;
  }
}

export const savePlan = async(plan, leadTime, language) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/planning`,
      { plan, leadTime, language }
    );
    return response.data;
  } catch(err) {
    if (err.response) {
      throw new Error(
        JSON.stringify({
          error: err.response.statusText,
          status: err.response.status,
        })
      );
    }

    console.error('Erro ao buscar getLatestPlan:', err.message);
    throw err;
  }
}

export const getStatus = async(operationId) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/planning/status/${operationId}`);
    return response.data;
  } catch(err) {
    if (err.response) {
      throw new Error(
        JSON.stringify({
          error: err.response.statusText,
          status: err.response.status,
        })
      );
    }

    console.error('Erro ao buscar getLatestPlan:', err.message);
    throw err;
  }
}
