const messages = {
  en: {
    translations: {
      loginWithGoogle: "Log in with Google",
      or: "Or",
      email: "Email",
      password: "Password",
      login: "Log in",
      invalidCredentials: "Invalid credentials",
      forgotPassword: "Forgot password",
      noAccount: "Don't have an account?",
      createAccount: "Create account",
      recoverPassword: "Password Recovery",
      typeEmail: "Enter your email used in Sumply",
      continue: "Continue",
      backToLogin: "Back to login",
      recoveryEmailSent:
        "We’ve sent a link to your email to reset your password.",
      goToLogin: "Go to login",
      authentication: "Authentication",
      step1Description: "Step 1: Who are you?",
      needToKnow: "We need to know you",
      registration: "Registration",
      step2Description: "Step 2: Registering in Sumply",
      needDetails: "We need details",
      integrations: "Integrations",
      step3Description: "Step 3: Connect your data",
      needData: "We need data!",
      invalidEmail: "Please enter a valid email",
      minPassword: "Minimum 8 characters",
      confirmPassword: "Confirm Password",
      passwordsDontMatch: "Passwords must match",
      continueWithGoogle: "Continue with Google",
      next: "Next",
      agreeWithTerms:
        "By clicking 'Next' or 'Continue with Google', you agree to the",
      termsOfUse: "Terms of Use",
      andThe: "and the",
      privacyPolicy: "Privacy Policy",
      alreadyHaveAccount: "Already have an account?",
      yourName: "Your Name",
      companyName: "Your company name",
      companySector: "Your company sector",
      employeeNumber: "Number of employees",
      acceptMailingList: "I agree to join Sumply's email list",
      back: "Back",
      autoPartsAndServices: "Auto Parts and Auto Services",
      cosmeticsAndHygiene: "Cosmetics and Personal Care",
      pharmaciesAndDrugstores: "Pharmacies and Drugstores",
      beautyAndHairSalons: "Beauty and Hair Salons",
      franchises: "Franchises",
      bookstoresAndStationeries: "Bookstores and Stationeries",
      buildingMaterials: "Building Materials",
      furnitureAndElectronics: "Furniture, Electronics and Dept. Stores",
      opticsAndJewelry: "Optics and Jewelry",
      otherServices: "Other Services",
      otherRetailers: "Other Retailers",
      gasStations: "Gas Stations",
      recreationAndLeisure: "Recreation and Leisure",
      supermarketsAndHypermarkets: "Supermarkets and Hypermarkets",
      tourismAndTransport: "Tourism and Transport",
      specializedFoodRetail: "Specialized Food Retail",
      clothingAndSportsGoods: "Clothing and/or Sports Goods",
      veterinariesAndPetShops: "Veterinaries and Pet Shops",
      ensureContinuousFlow:
        "Ensure a continuous and automated flow of information!",
      optimizeInternalProcesses:
        "Optimize internal processes, eliminating rework and reducing errors.",
      integrationDescription:
        "With the integration, vital data like sales, stock, and finances are synced in real-time, giving a holistic view of your business.",
      decisionMakingWithRealTimeData:
        "Decision-making based on updated, accurate information drives operational agility, allowing your team to focus on business growth, instead of manual repetitive tasks.",
      connectToERP: "Connect to your ERP",
      connect: "Connect",
      connected: "Connected",
      finish: "Finish",
      erpNotListed: "Your ERP isn't listed?",
      contactUs: "Contact us",
      skipERPConnection: "Skip ERP connection?",
      skipThisStep: "Skip this step",
      disconnect: "Disconnect",
      userRegistrationError: "User registration error",
      userRegistrationErrorMessage:
        "An error occurred during user registration. Please try again or contact the Sumply team.",
      return: "Return",
      opportunities: "Opportunities",
      current: "Current",
      allFem: "All",
      allMasc: "All",
      salesPlan: "Sales Plan",
      productMix: "Product Mix",
      profile: "Profile",
      openMenu: "Open menu",
      closeMenu: "Close menu",
      seasons: "Seasons",
      categories: "Categories",
      channels: "Channels",
      business: "Business",
      sales: "Sales",
      factor: "Factor",
      performance: "Performance",
      velocity: "Speed",
      salesInValue: "Sales in Value",
      actualVsProjected: "Actual vs Projected",
      projected: "Projected",
      saleInUnits: "Sales in Units",
      stock: "Stock",
      health: "Health",
      coverage: "Coverage",
      stockDays: "Stock (Days)",
      value: "Value",
      sku: "SKU",
      salesPerformanceAbbreviation: "Sales Performance",
      salesPerformance: "Sales Performance",
      salesSpeedAbbreviation: "Sales Speed",
      salesSpeed: "Sales Speed",
      stockHealth: "Stock Health",
      stockCoverageAbbreviation: "Stock Coverage",
      stockCoverage: "Stock Coverage",
      revenueValue: "Revenue (R$)",
      revenueShare: "Revenue (Share)",
      revenueGrowthLastYear: "Revenue Growth vs Last Year",
      averageSellingPrice: "Average Selling Price (R$)",
      salesValueShare: "Sales Value Share (%)",
      stockUnits: "Stock (Units)",
      stockValue: "Stock (R$)",
      coverageDays: "Coverage (Days)",
      excelente: "excellent",
      crítico: "critical",
      ótimo: "great",
      Regular: "Regular",
      excellent: "Excellent",
      great: "Great",
      regular: "Regular",
      critical: "Critical",
      speed: "Speed",
      planning: "Planning",
      planOverview: "Plan Overview",
      businessView: "Business View",
      currentStockSituation: "Current Stock Situation",
      totalOpenActions: "Total Open Actions",
      totalROIInActions: "Total Actions ROI",
      generalStatus: "General Status",
      stockExcess: "Stock Surplus",
      stockLack: "Stock Shortage",
      category: "Category",
      suggestedActions: "Suggested Actions",
      actionsROI: "Actions ROI",
      status: "Status",
      adjustPlan: "Adjust Plan",
      actionsAnalysis: "Actions Analysis",
      planAdjustment: "Plan Adjustment",
      adjustmentAlignment: "Adjustment Alignment",
      companyStockHealth: "Company Stock Health - Company View",
      compareLY: "Compare Last Year",
      excess: "Surplus",
      lack: "Shortage",
      unitSales: "Unit Sales",
      growthPercent: "Growth % vs LY",
      LY: "LY",
      categoryStockHealth: "Category Stock Health - Category View",
      selectCategory: "Select a category",
      loadingSimulation: "Loading simulation...",
      successAdjustPlan: "The plan has been successfully adjusted!",
      errorAdjustPlan:
        "An error occurred while adjusting the plan. Please try again or contact the system administrator.",
      simulate: "Simulate",
      refreshToSimulate: "Refresh the page to simulate",
      idealStock: "Ideal stock for sales",
      stockExcessOrLack: "Surplus/Shortage of stock",
      salesShare: "Sales Share",
      salesTarget: "Sales Target",
      currencySales: "Sales in R$",
      averagePrice: "Average Price",
      inventoryPortfolio: "Portfolio",
      receiptProjection: "Receipt Projection",
      finalMonthStock: "Final month stock",
      unitStock: "Unit Stock",
      stockTurnover: "Stock Turnover",
      saveValues: "Save values",
      toOriginalValues: "Return to original values",
      calculate: "Calculate",
      leadTime: "Lead time",
      salesCurve: "Sales curve",
      createPlan: "Create Plan",
      next6Months: "Next 6 months",
      untilTheEndOfYear: "Until the end of the year",
      next12Months: "Next 12 months",
      untilEndNextYear: "Until the end of next year",
      custom: "Custom",
      planningExplanationText:
        "To start your strategic sales and stock planning, you need to define the period you want to plan for and your growth target during that interval. We will send you tips, and you can make adjustments as needed based on your sales. We will notify you when this period is nearing its end so you can create the next one.",
      selectPeriodPlanning:
        "Select the period for which you want to make your plan and the value projection you wish to achieve within that period.",
      salesChannelsExplanation:
        "You can separate your planning by the sales channels your company operates in. This provides the ability to analyze sales and receipt dates specifically for each channel.",
      listSalesChannels:
        "List your company's sales channels and provide the participation target for each.",
      dontSeparateChannels: "Do not separate by sales channels",
      goalParticipation: "Goal Participation",
      digital: "Digital",
      adjustParticipationSum:
        "To proceed, adjust the participation so that the total is 100%.",
      alt: "Delivery Time",
      deliveryTime: "Delivery Time",
      leadTimeStepDescription:
        "Now you will define an average lead time per category. Think of the time in weeks between placing the order with the supplier and receiving that order in-store. With these inputs, you will be able to optimize your stock levels, ensuring your products are available when and where they are needed. We use the categories registered for your items.",
      averageLeadTimeQuestion:
        "What is the average number of weeks from when you order the product from the supplier until it arrives in-store?",
      sameTimeForAll: "Set the same delivery time for all categories",
      timeInDays: "Time in days",
      days: "days",
      fillAllCategories:
        "Fill in the lead time for all categories before moving on to the next step.",
      editCategories: "Edit categories",
      commercialDatesExplanation:
        "Commercial dates play a crucial role in retail, representing strategic opportunities to drive sales through increased consumer flow. Take a closer look at them by selecting those that align significantly with your business profile. You can also add custom dates.",
      selectCommercialDates:
        "Select the commercial dates you are interested in",
      insertCustomDate: "Insert custom commercial date",
      commercialDateName: "Commercial date name",
      typeName: "Type the name",
      reviewCommercialDate:
        "Please correct the information for the new custom date. Check if the date is valid and ensure there is no other identical custom date.",
      maxCommercialDates:
        "The maximum number of custom dates has been reached. To insert another date, click on an existing custom date to deactivate it.",
      noCommercialDatesConfirmation:
        "Commercial Dates are those that impact your retail sales and are important for calculating the demand curve for products. By clicking next, you confirm that no commercial dates affect the sales of your channels?",
      cancel: "Cancel",
      month: "Month",
      salesUnits: "Sales (units)",
      salesValueParenthesis: "Sales (value)",
      participation: "Participation",
      salesCurveStepExplanation: "Sales curve analysis is crucial for understanding sales behavior over time. We generated a recommended sales curve for you based on sales history. Analyze this and other curves and choose which one to use as the model for your sales curve this year.",
      accessories: "Accessories",
      chart: "Chart",
      table: "Table",
      salesValue: "Sales Value",
      salesTable: "Sales Table",
      otherCurves: "Other Curves",
      footwear: "Footwear",
      women: "Feminine",
      children: "Children",
      men: "Masculine",
      suggested: "Sugerida",
      completePlanning: "Complete Planning",
      confirmationOfPlanningData: "Confirmation of Planning Data",
      beforeProceedingCheckData:
        "Before proceeding, check the data you entered.",
      targetFor: "Target for",
      planningPeriod: "Planning period",
      targetForPeriod: "Target for the period",
      salesChannels: "Sales channels",
      noSalesChannelDefined: "No sales channel defined.",
      physicalStore: "Physical Store",
      wholesale: "Wholesale",
      commercialDates: "Commercial Dates",
      noCommercialDateDefined: "No commercial date defined.",
      reviewData: "Review Data",
      complete: "Complete",
      pleaseWait: "Please wait...",
      yourPlanningIsBeingCreated:
        "Your planning is being created... This may take a few minutes.",
      success: "Success!",
      yourPlanningWasCreated: "Your planning was created! Continue to view it.",
      goToPlanning: "Go to Planning",
      errorOccurred: "An error occurred.",
      errorCreatingPlanning:
        "An error occurred while creating the planning. Please try again.",
      goBackToReviewPlanning: "Go back to planning review",
      youAreOneStepAway: "You are one step away from improving your results!",
      growthProjectionExplanation:
        "The Growth Projection is all we need to start positively impacting your business.",
      selectMethod: "Select the method you want to use:",
      salesTargetForThisYear: "Sales target for this year",
      useInflationIndex: "Use Inflation Index",
      enterProjections:
        "Enter the projected value or percentage growth you want to achieve this year.",
      type: "Type",
      inflationIndexUpdated:
        "The inflation index is updated according to the website",
      insertLYRevenue: "Enter last year's revenue",
      invalidPassword: "Invalid password",
      passwordHelperText: "Minimum 8 characters.",
      invalidConfirmPassword:
        "Confirm password: The password is different from the one previously entered",
      newPassword: "New Password",
      enterNewPassword: "Enter a new password for your account.",
      passwordChanged: "Password successfully changed!",
      canLoginNow: "You can now log in.",
      unexpectedError: "An unexpected error occurred!",
      target: "Target",
      period: "Period",
      initialMonthError:
        "The initial month must be the current or a future month.",
      minPeriodError:
        "The period must be at least 1 month and no more than 18 months.",
      name: "Name",
      periodStartInclusive: "Period Start (inclusive)",
      periodEndInclusive: "Period End (inclusive)",
      selectedPeriod: "Selected period",
      date: "Date",
      noDateSelected: "No date was selected",
      generalInformation: "General Information",
      revenueRs: "Revenue (RS)",
      revenueGrowth: "Revenue Growth vs Last Year",
      averageSalesPrice: "Average Sales Price (R$)",
      stockPcs: "Stock (Pcs)",
      stockRs: "Stock (R$)",
      sumplyFactors: "Sumply Factors",
      noMessage: "No Message",
      percentShareByCluster: "% Sales Share by Cluster",
      coverageInDays: "Coverage (days)",
      criticalSlow: "Critical (Slow)",
      criticalFast: "Critical (Fast)",
      greatFem: "Great",
      loading: "Loading",
    },
  },
};

export { messages };
