import React from "react";
import {Navigate, Outlet} from "react-router-dom";
import {AuthGuard} from "./AuthGuard";

export const AuthLayout = () => {
    const token = localStorage.getItem('sumply:access_token');

    if (!token) {
        return <Navigate to="/" replace/>;
    }

    return <AuthGuard>
        <Outlet/>
    </AuthGuard>;
};
