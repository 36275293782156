import { useRequestMeQuery } from "../../pages/Login/authService";
import React from "react";
import {Navigate, Outlet} from "react-router-dom";
import {LoadingLayout} from "../loadingLayout";
import {useDispatch, useSelector} from "react-redux";
import { setUser } from "../../pages/Login/authReducer";

export const AuthGuard = () => {
    const dispatch = useDispatch();
    const userData = useSelector(state => state.auth.user);
    const { data: user, isLoading, isError, isSuccess } = useRequestMeQuery();

    if (isLoading) {
        return <LoadingLayout/> // Or your loading component
    } else if (isSuccess && user ) {
        if(!userData) dispatch(setUser(user));
        return <Outlet/>
    } else if (isError) {
        localStorage.removeItem("sumply:access_token");
        return <Navigate to="/" replace />;
    }
};
