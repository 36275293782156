import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Number = styled.p`
  font-size: 30px;
  font-weight: bold;
`;
