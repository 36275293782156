import React, { useState} from "react";
import PropTypes from "prop-types";
import {
    Flash,
    HealthCross,
    Rocket,
    FlashFilled,
    Box,
} from "@carbon/icons-react";
import {i18n} from "../../../translate/i18n";
import * as S from "./table.style";
import ProductTableDetailsModal from "../ProductTableDetailsModal/ProductTableDetailsModal";
import {BoxFilled, HealthCrossFilled, RocketFilled} from "../../../components/LocalIcons";
import {language} from "../../../consts/language";
import TableHeader from "./TableHeader";
const {t} = i18n;

function truncateString(str, maxLength = 10) {
    return str.length > maxLength ? str.slice(0, maxLength) + "..." : str;
}

const accordingStatus = (status, Icons, FilledIcons) => {
    function capitalizeFirstLetter(word) {
        const translate = t(word);
        return translate.charAt(0).toUpperCase() + translate.slice(1).toLowerCase();
    }

    switch (status) {
        case "excelente": {
            return (
                <div className="icons">
            <span className="text-status-icon">
              {capitalizeFirstLetter(status)}
            </span>
                    <FilledIcons/>
                    <FilledIcons/>
                    <FilledIcons/>
                    <FilledIcons/>
                </div>
            );
        }
        case "ótimo": {
            return (
                <div className="icons">
            <span className="text-status-icon">
              {capitalizeFirstLetter(status)}
            </span>
                    <FilledIcons/>
                    <FilledIcons/>
                    <FilledIcons/>
                    <Icons/>
                </div>
            );
        }
        case "regular": {
            return (
                <div className="icons">
            <span className="text-status-icon">
              {capitalizeFirstLetter(status)}
            </span>
                    <FilledIcons/>
                    <FilledIcons/>
                    <Icons/>
                    <Icons/>
                </div>
            );
        }
        case "crítico": {
            return (
                <div className="icons">
            <span className="text-status-icon">
              {capitalizeFirstLetter(status)}
            </span>
                    <FilledIcons/>
                    <Icons/>
                    <Icons/>
                    <Icons/>
                </div>
            );
        }

        default: {
            return null;
        }
    }
};

const buildColumnIcons = (type, value) => {
    if (type === "performance") {
        return (
            <S.tdMultLine>
                {accordingStatus(value, Rocket, RocketFilled)}
            </S.tdMultLine>
        );
    }
    if (type === "velocidade") {
        return (
            <S.tdMultLine>
                {accordingStatus(value, Flash, FlashFilled)}
            </S.tdMultLine>
        );
    }
    if (type === "saude") {
        return (
            <S.tdMultLine>
                {accordingStatus(value, HealthCross, HealthCrossFilled)}
            </S.tdMultLine>
        );
    }
    if (type === "cobertura") {
        return (
            <S.tdMultLine>{accordingStatus(value, Box, BoxFilled)}</S.tdMultLine>
        );
    }
    return null;
};

function Table({products = [], metrics}) {
    const [startModal, setStartModal] = useState(false);
    const [valueSendModal, setValueSendModal] = useState(null);

    return (
        <S.table>
            <TableHeader t={t} metricas={metrics}/>

            <S.tbody>
                {products.map(
                    ({
                         id,
                         sku,
                         cor,
                         produto,
                         cluster,
                         idade,
                         st,
                         giro,
                         receita,
                         shareReceita,
                         crescReceita,
                         precoMedioVenda,
                         shareValorVenda,
                         estoquePcs,
                         estoqueRs,
                         cobertura,
                         feedbackMessage
                     }) => (
                        <S.tr
                            key={id}
                            color
                            onClick={() => {
                                setStartModal(true);
                                setValueSendModal({
                                    cor,
                                    produto,
                                    sku,
                                    cluster,
                                    st,
                                    giro,
                                    idade,
                                    receita,
                                    shareReceita,
                                    crescReceita,
                                    precoMedioVenda,
                                    shareValorVenda,
                                    estoquePcs,
                                    estoqueRs,
                                    cobertura,
                                    feedbackMessage,
                                });
                            }}>
                            <S.td>{id}</S.td>
                            <S.td>
                                <S.tdMultLine>
                                    <div className="lineColor">{sku}</div>
                                    <strong>
                                        <div>{truncateString(produto)}</div>
                                    </strong>
                                    <div>{cor}</div>
                                </S.tdMultLine>
                            </S.td>
                            <S.td>{buildColumnIcons("performance", cluster.toLowerCase())}</S.td>
                            <S.td>{buildColumnIcons("velocidade", st.toLowerCase())}</S.td>
                            <S.td>{buildColumnIcons("saude", giro.toLowerCase())}</S.td>
                            <S.td>{buildColumnIcons("cobertura", idade.toLowerCase())}</S.td>
                            <S.td>
                                {receita.toLocaleString(language, {
                                    style: "currency",
                                    currency: "BRL",
                                })}
                            </S.td>
                            <S.td>{shareReceita}</S.td>
                            <S.td>{crescReceita || 0}</S.td>
                            <S.td>
                                {precoMedioVenda.toLocaleString(language, {
                                    style: "currency",
                                    currency: "BRL",
                                })}
                            </S.td>
                            <S.td>{shareValorVenda}</S.td>
                            <S.td>{estoquePcs}</S.td>
                            <S.td>
                                {estoqueRs.toLocaleString(language, {
                                    style: "currency",
                                    currency: "BRL",
                                })}
                            </S.td>
                            <S.td>{cobertura}</S.td>
                        </S.tr>
                    )
                )}
            </S.tbody>

            {valueSendModal && (
                <ProductTableDetailsModal
                    open={startModal}
                    item={valueSendModal}
                    setShow={setStartModal}
                />
            )}
        </S.table>
    );
}

Table.propTypes = {
    metrics: PropTypes.shape({
        cluster: PropTypes.shape({
            classify: PropTypes.string.isRequired,
            percentage: PropTypes.string.isRequired,
        }),
        sellThrough: PropTypes.shape({
            classify: PropTypes.string.isRequired,
            percentage: PropTypes.string.isRequired,
        }),
        age: PropTypes.shape({
            classify: PropTypes.string.isRequired,
            percentage: PropTypes.string.isRequired,
        }),
        giro: PropTypes.shape({
            classify: PropTypes.string.isRequired,
            percentage: PropTypes.string.isRequired,
        }),
    }),
    products: PropTypes.array
};

export default Table;
