import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    email: '',
    password: '',
    user: null,
}

const authSlice = createSlice({
    name: 'authSlice',
    initialState,
    reducers: {
        updateLoginFormField(state, {payload}) {
            if(['email', 'password'].includes(payload.field)) {
                state[payload.field] = payload.value
            }
        },
        setUser(state, {payload}) {
            state.user = payload
        }
    },
})

export const { updateLoginFormField, setUser } = authSlice.actions
export const authReducer = authSlice.reducer