import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const dashboardApi = createApi({
    reducerPath: 'dashboardApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_URL}/api/dashboard/`,
        prepareHeaders: (headers, { getState }) => {
            const token = localStorage.getItem('sumply:access_token');
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        }
    }),
    endpoints: (builder) => ({
        getMetricsPerformance: builder.query({
            query: (id) => `metricsPerformance/${id}`
        }),
        getProjections: builder.query({
            query: (id) => `projections/${id}`
        }),
        getStockDaysWithPercent: builder.query({
            query: (id) => `stockDaysWithPercent/${id}`
        }),
        getStockValues: builder.query({
            query: (id) => `stockValues/${id}`
        }),
        getClusterValues: builder.query({
            query: (id) => `clusterValues/${id}`
        })
    })
});

export const {
    useGetMetricsPerformanceQuery,
    useGetProjectionsQuery,
    useGetStockDaysWithPercentQuery,
    useGetStockValuesQuery,
    useGetClusterValuesQuery
} = dashboardApi;
export const dashboardReducer = dashboardApi.reducer;
