import React from "react";
import ReactDOM from "react-dom/client";
import {Helmet} from "react-helmet";
import AppRoutes from "./router";
import "./index.scss";
import { RouterProvider } from 'react-router-dom';

import {Provider} from 'react-redux'
import {store} from './store'

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <Helmet>
            <meta charSet="utf-8"/>
            <title>Sumply</title>
        </Helmet>
        <Provider store={store}>
            <RouterProvider router={AppRoutes}/>
        </Provider>
    </React.StrictMode>
)
