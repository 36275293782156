import styled, { css } from "styled-components";
import { Tab as CTab } from "@carbon/react";

export const Section = styled.section``;

export const Title = styled.article`
  display: flex;
  gap: 24px;
  padding: 24px 0;
  align-items: center;

  h3 {
    font-size: 32px;
    font-weight: 600;
  }
`;

export const Text = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #7d7d7d;
`;

export const Tab = styled(CTab)`
  ${(props) => {
    if (props.completed) {
      return css`
        color: #5bce7f !important;
        border-block-end: 2px solid #5bce7f !important;
      `;
    }
    if (props.selected) {
      return css`
        border-block-end: 2px solid #000 !important;
      `;
    }
  }};
`;

export const Loading = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  height: 100vh;
  justify-content: center;

  .icon-spin {
    animation: rotate 1s linear infinite;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
