import React, { useContext, useEffect, useState } from "react";
import {
  Column,
  Grid,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@carbon/react";
import { ArrowRight, ArrowLeft } from "@carbon/icons-react";
import Icon from "../../../assets/icons/Chart--histogram.svg";
import Graphic from "../../../assets/icons/graphic.svg";
import { i18n } from "../../../translate/i18n";

import * as S from "./SalesGraphStep.style";
import { CreatePlanContext } from "../../../contexts/CreatePlanContext";

import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import { language } from "../../../consts/language";
import PropTypes from 'prop-types';

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

const { t } = i18n;

const header = [
  t("month"),
  t("salesUnits"),
  t("salesValueParenthesis"),
  t("participation"),
];

export default function SalesGraphStep({ onBackStep, onCompleteStep }) {
  const [currentCurveData, setCurrentCurveData] = useState([]);
  const [allCurves, setAllCurves] = useState(null);

  const {
    selectedMonths,
    selectedCurveId,
    setSelectedCurveId,
    writeMonthLabel,
    periodTarget,
    setSalesCurve
  } = useContext(CreatePlanContext);

  useEffect(() => {
    const fetchSalesCurve = async () => {
      try {
        const result = await setSalesCurve(periodTarget, selectedMonths);
        setAllCurves(result.curves);
        setCurrentCurveData(result.curves[selectedCurveId] || []);
      } catch (error) {
        console.error("Error fetching sales curve:", error);
      }
    };

    fetchSalesCurve();
  }, [periodTarget, selectedMonths, selectedCurveId]);

  useEffect(() => {
    if (allCurves) {
      setCurrentCurveData(allCurves[selectedCurveId] || []);
    }
  }, [selectedCurveId, allCurves]);

  const tableData = currentCurveData.map(data => ({
    ...data,
    month: writeMonthLabel(data.month),
  }));

  const months = tableData.map((data) => data.month);
  const curvesNames = ['accessories', 'footwear', 'women', 'children', 'men'];

  return (
    <Grid style={{ marginTop: 60 }}>
      <Column lg={6} md={4} sm={4}>
        <S.Title>
          <img src={Icon} alt="" />
          <h4>{t("salesCurve")}</h4>
        </S.Title>
        <S.Text>{t("salesCurveStepExplanation")}</S.Text>
      </Column>
      <S.Column lg={10} md={4} sm={4}>
        <S.H3>{t(curvesNames[selectedCurveId])}</S.H3>
        <Tabs>
          <TabList contained>
            <S.Tab>{t("chart")}</S.Tab>
            <S.Tab>{t("table")}</S.Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Chart
                type="bar"
                data={{
                  labels: months,
                  datasets: [
                    {
                      type: "bar",
                      label: t("salesValue"),
                      backgroundColor: "rgb(75, 192, 192)",
                      data: tableData.map((data) => data.salesValue),
                      borderColor: "white",
                      borderWidth: 2,
                    },
                  ],
                }}
              />
            </TabPanel>
            <TabPanel>
              <S.Table aria-label={t("salesTable")}>
                <TableHead>
                  <TableRow>
                    {header.map((head) => (
                      <TableHeader key={head}>{head}</TableHeader>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData.map((data) => (
                    <TableRow key={data.month}>
                      <TableCell>{data.month}</TableCell>
                      <TableCell>{data.salesUnits}</TableCell>
                      <TableCell>
                        {data.salesValue ? 
                          data.salesValue.toLocaleString(language, {
                            style: "currency",
                            currency: "BRL",
                          }) 
                          : '0'}
                      </TableCell>
                      <TableCell>{data.participation}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </S.Table>
            </TabPanel>
          </TabPanels>
        </Tabs>

        <S.H4>{t("otherCurves")}</S.H4>
        <S.ButtonGraphsGroup>
          {[0, 1, 2, 3, 4].map((curveId) => (
            <S.ButtonGraphs
              key={curveId}
              isSelected={selectedCurveId === curveId}
              onClick={() => setSelectedCurveId(curveId)}
            >
              <p>{t(curvesNames[curveId])}</p>
              <img src={Graphic} alt="" />
            </S.ButtonGraphs>
          ))}
        </S.ButtonGraphsGroup>

        <S.ButtonSet>
          <S.Button
            kind="secondary"
            onClick={() => onBackStep(4)}
          >
            <ArrowLeft /> {t("back")}
          </S.Button>
          <S.Button
            primary
            renderIcon={() => <ArrowRight />}
            onClick={() => onCompleteStep(4)}
          >
            {t("completePlanning")}
          </S.Button>
        </S.ButtonSet>
      </S.Column>
    </Grid>
  );
}

SalesGraphStep.propTypes = {
  onBackStep: PropTypes.func.isRequired,
  onCompleteStep: PropTypes.func.isRequired
}