import axios from "./BaseService";

export const getProducts = async (temporada) => {

   try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/products/${temporada}`);
    return response.data;
   } catch(err) {
    if (err.response) {
      throw new Error(
        JSON.stringify({
          error: err.response.statusText,
          status: err.response.status,
        })
      );
    }
    console.error("erro em get products: ", err.message);
    throw err;
   }
};

export const updateProducts = async(newProducts) => {
  try {
    const response = await axios.patch(`${process.env.REACT_APP_API_URL}/api/products`, newProducts);
    return response.data;
  } catch(err) {
    if (err.response) {
      throw new Error(
        JSON.stringify({
          error: err.response.statusText,
          status: err.response.status,
        })
      );
    }
    console.error('erro em udate products: ', err.message);
    throw err;
  }
}

export const createProducts = async () => {

 try {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/products/forcedGeneration/15`);
  return response.data;
 } catch(err) {
  if (err.response) {
    throw new Error(
      JSON.stringify({
        error: err.response.statusText,
        status: err.response.status,
      })
    );
  }
  console.error('erro em create products: ', err.message);
  throw err;
 }
};